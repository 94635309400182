import { Container, InputAdornment, Box, Slide, Typography, useTheme } from '@mui/material';
import React from 'react';
import { StepWizardChildProps } from 'react-step-wizard';
import { showErrorOnBlur, TextField } from 'mui-rff';
import EmailIcon from '@mui/icons-material/Email';
import { useForm } from 'react-final-form';
import { useLocation } from 'react-router';
import { FormMessage, ReassuranceElementsFormPage } from '..';
import { NextPrevButtonGroup } from '../inputs';
import Loader from '../Loader';
import { FormAnswer } from '../../entities';
import { getTemplates, getSkills, createTrainingAndLearner } from '../../http/bubble';
import { createLead } from '../../http/pipedream';
import getProgram from '../../utils/templateAttribution';
import TopicSentences from './types/TopicSentences';

const createStartingLevelString = (formAnswers: FormAnswer) => {
  const questionsForExcel = `== Qts Excel ==
Lire et naviguer dans un tableau simple : ${formAnswers.skillExcelSimpleTable}
Étendre une formule avec la poignée de recopie : ${formAnswers.skillExcelDragFormula}
Utiliser le "$" dans une formule pour bloquer une cellule : ${formAnswers.skillExcelFrozeFormula}
Gérer les Tableaux Croisés Dynamiques : ${formAnswers.skillExcelTCD}`;
  const questionsForWordExcel = `== Qts Excel + Word ==
Modifier la police et la couleur d'un texte : ${formAnswers.skillWordChangeFont}
Créer une formule de calcul sur Excel : ${formAnswers.skillExcelFormula}
Faire une table des matières sur Word : ${formAnswers.skillWordTableOfContent}
`;
  const questionsForOffice = `== Qts Bureautique ==
Modifier la police et la couleur d'un texte : ${formAnswers.skillWordChangeFont}
Créer une formule sur Excel : ${formAnswers.skillExcelFormula}
Faire une table des matières sur Word : ${formAnswers.skillWordTableOfContent}
Ajouter une animation à une diapositive dans une présentation PowerPoint : ${formAnswers.skillPowerpointSlidePowerpoint}`;
  const questionsForOutlook = `
== Qts Outlook ==
Modifier la police et la couleur d'un email : ${formAnswers.skillOutlookChangeEmailFont}
Envoyer un mail avec des destinataires en CC: et en CCI: : ${formAnswers.skillOutlookEmailCC}
Créer un évènement sur mon calendrier Outlook et inviter un(e) participant(e) : ${formAnswers.skillOutlookCalendar}
Créer un contact sur Outlook : ${formAnswers.skillOutlookAddContact}`;

  const questionsHashtable: TopicSentences = {
    Excel: questionsForExcel,
    Outlook: questionsForOutlook,
    'Excel & Word': questionsForWordExcel,
    'La bureautique': questionsForOffice,
  };

  const questions = questionsHashtable[formAnswers.topic];
  const startingLevel = `Niveau : ${formAnswers.selfAssessedLevel}
Urgence : ${formAnswers.startDate}
Posession ordi : ${formAnswers.computerOwnership}
${
  formAnswers.selfAssessedLevel !== 'beginner'
    ? questions
    : `Usage Ordi : ${formAnswers.computerUsage}`
}`;

  return startingLevel.replace(/skillUnknown/g, '❌').replace(/skillKnown/g, '✅');
};

const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()  [\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const validateEmail = (value: string) => (EMAIL_REGEX.test(value) ? undefined : 'Email invalide');

const ContactInfo: React.FC<Partial<StepWizardChildProps>> = props => {
  const location = useLocation();
  const theme = useTheme();
  const searchParams = new URLSearchParams(location.search);
  const form = useForm<FormAnswer>('useFormState');
  let formState = form.getState();
  const { selfAssessedLevel } = formState.values;
  if (props.goToNamedStep && !selfAssessedLevel) {
    props.goToNamedStep('topicChoice');
    return <Loader />;
  }

  const createFormationProposal = async (formAnswers: FormAnswer) => {
    const analytics = {
      utm_source: searchParams.get('utm_source'),
      utm_medium: searchParams.get('utm_medium'),
      utm_term: searchParams.get('utm_term'),
      utm_content: searchParams.get('utm_content'),
      utm_campaign: searchParams.get('utm_campaign'),
      msclkid: searchParams.get('msclkid'),
      gclid: searchParams.get('gclid'),
      fbclid: searchParams.get('fbclid'),
    };

    // get templates and skills from bubble
    const [templates, skills] = await Promise.all([getTemplates(), getSkills()]);

    // generate program
    const [formulaA, formulaB, formulaC, formulaD] = getProgram({
      templates,
      skills,
      formAnswers,
    });

    const { trainingId, learnerId } = await createTrainingAndLearner({
      email: formAnswers.email.trim(),
      objective: formAnswers.objective,
      startingLevel: createStartingLevelString(formAnswers),
      topic: formAnswers.topic,
      skillsOfFormulaA: formulaA.skills.map(s => s.id),
      skillsOfFormulaB: formulaB.skills.map(s => s.id),
      skillsOfFormulaC: formulaC.skills.map(s => s.id),
      skillsOfFormulaD: formulaD.skills.map(s => s.id),
      formulaMfcLinkA: formulaA.mfcLink,
      formulaMfcLinkB: formulaB.mfcLink,
      formulaMfcLinkC: formulaC.mfcLink,
      formulaMfcLinkD: formulaD.mfcLink,
    });

    const backofficeUrlsForAdmin = {
      offerUrl: `https://bureautique.atoria.co/programme/${trainingId}?admin=true`,
      adminOfferUrl: `https://app.atoria.co/admin-formations-item/${learnerId}`,
    };

    // send form answers to zapier to trigger a zap that will create the lead in pipedrive and other tools.
    if (process.env.NODE_ENV === 'production') {
      await createLead(
        formAnswers,
        createStartingLevelString(formAnswers),
        analytics,
        backofficeUrlsForAdmin,
      );
    }
  };

  const goToNextStep = () => {
    formState = form.getState();
    if (props.goToNamedStep && !formState.hasValidationErrors) {
      createFormationProposal(formState.values);
      props.goToNamedStep('contactInfo');
    }
  };

  return (
    <Container
      maxWidth="lg"
      sx={{
        height: '65vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingTop: 2,
      }}
    >
      <Slide timeout={1200} direction="left" in mountOnEnter unmountOnExit>
        <Container
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
          maxWidth="md"
        >
          <FormMessage sx={{ paddingBottom: '40px' }}>
            <Typography color="grey.100">
              Bonne nouvelle, votre programme sur mesure est prêt ! Afin de vous adresser le devis
              certifiant et finançable par le CPF 🇫🇷, j’ai besoin de vos coordonnées.
            </Typography>
          </FormMessage>

          <Container
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-around',
              alignItems: 'center',
              marginBottom: 4,
            }}
            maxWidth="sm"
          >
            <TextField
              label="Votre email"
              name="email"
              required
              data-heap-textfield="email"
              showError={showErrorOnBlur}
              fieldProps={{
                validateFields: [],
                validate: validateEmail,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <EmailIcon
                      sx={{ color: theme.palette.mode === 'dark' ? 'grey.50' : 'grey.500' }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </Container>

          <Box paddingTop={5}>
            <ReassuranceElementsFormPage />
          </Box>
        </Container>
      </Slide>
      <NextPrevButtonGroup
        nextStep={goToNextStep}
        disableNextButton={formState.hasValidationErrors}
        forceMobileDisplay
      />
    </Container>
  );
};
export default ContactInfo;
