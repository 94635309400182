import { Container, Typography, CircularProgress } from '@mui/material';
import React, { useEffect } from 'react';
import { useForm } from 'react-final-form';
import { StepWizardChildProps } from 'react-step-wizard';
// import theme from '../../theme';
// import atoriaTeam from '../../images/atoria-team.png';
import { FormAnswer } from '../../entities';
import Loader from '../Loader';

const Generation: React.FC<Partial<StepWizardChildProps>> = props => {
  // const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const form = useForm<FormAnswer>('useFormState');
  let formState = form.getState();
  const { selfAssessedLevel } = formState.values;
  if (props.goToNamedStep && !selfAssessedLevel) {
    props.goToNamedStep('topicChoice');
    return <Loader />;
  }

  const goToNextStep = () => {
    formState = form.getState();
    if (props.goToNamedStep && !formState.hasValidationErrors) {
      props.goToNamedStep('email');
    }
  };

  useEffect(() => {
    setTimeout(() => {
      goToNextStep();
    }, 4000);
  }, []);

  return (
    <Container
      maxWidth="lg"
      sx={{
        mt: '100px',
        height: '65vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography align="center" variant="h1" sx={{ marginBottom: 4 }}>
        Nous personnalisons votre programme de formation
      </Typography>
      <CircularProgress size={80} sx={{ marginBottom: 4 }} />
      {/* <Box>
        <Typography align="center" variant="h1" sx={{ marginBottom: 4 }}>
          L&apos;équipe Atoria 😍
        </Typography>
        <img
          src={atoriaTeam}
          alt="l'équipe d'Atoria"
          width={!isSmallScreen ? '60%' : '100%'}
          style={{
            display: 'block',
            margin: 'auto',
            borderRadius: 5,
            boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
          }}
        />
      </Box> */}
    </Container>
  );
};
export default Generation;
