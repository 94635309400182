import { FormAnswer } from '../entities';

const axios = require('axios').default;

export type Analytics = {
  utm_source: string | null;
  utm_medium: string | null;
  utm_term: string | null;
  utm_content: string | null;
  utm_campaign: string | null;
  msclkid: string | null;
  gclid: string | null;
  fbclid: string | null;
};

type BackofficeUrlsForAdmin = {
  offerUrl: string;
  adminOfferUrl: string;
};

const instance = axios.create({});

type LeadDataToUpdate = {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  analytics: Analytics;
};

export async function updateLeadOnCRM(leadDataToUpdate: LeadDataToUpdate) {
  const res = await instance.post(`https://eouw1bbrod84ima.m.pipedream.net`, leadDataToUpdate);

  if (res.status !== 200) {
    throw new Error(res.statusText);
  }

  return res.data;
}

export async function createLead(
  formAnswers: FormAnswer,
  startingLevel: string,
  analytics: Analytics,
  backofficeUrlsForAdmin: BackofficeUrlsForAdmin,
) {
  const today: Date = new Date();
  const res = await instance.post('https://eote41gip7bhakc.m.pipedream.net', {
    ...formAnswers,
    ...analytics,
    ...backofficeUrlsForAdmin,
    startingLevel,
    createdAt: today.toISOString(),
  });

  if (res.status !== 200) {
    throw new Error(res.statusText);
  }

  return res.data;
}
