import { Container, Slide, Typography } from '@mui/material';
import React from 'react';
import { StepWizardChildProps } from 'react-step-wizard';
import { useForm } from 'react-final-form';
import { FormMessage } from '..';
import { NextPrevButtonGroup, RadiosWithAnalytics } from '../inputs';
import { FormAnswer } from '../../entities';
import Loader from '../Loader';

const StartDate: React.FC<Partial<StepWizardChildProps>> = props => {
  const form = useForm<FormAnswer>('useFormState');
  let formState = form.getState();
  const { selfAssessedLevel } = formState.values;
  if (props.goToNamedStep && !selfAssessedLevel) {
    props.goToNamedStep('topicChoice');
    return <Loader />;
  }

  const goToNextStep = () => {
    formState = form.getState();
    if (props.goToNamedStep && !formState.hasValidationErrors) {
      props.goToNamedStep('computerOwnership');
    }
  };

  return (
    <Container
      maxWidth="lg"
      sx={{
        height: '65vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingTop: 2,
      }}
    >
      <Slide timeout={1200} direction="left" in mountOnEnter unmountOnExit>
        <Container
          sx={{
            height: '40vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
          maxWidth="md"
        >
          <FormMessage>
            <Typography marginBottom="20px" color="grey.100">
              Nous proposons des horaires larges de 7h à 23h, 7j/7 pour que vous trouviez les
              créneaux qui vous conviennent.
            </Typography>
            <Typography color="grey.50" variant="h1" fontSize={{ sx: '22px', sm: '24px' }}>
              Quand souhaitez-vous commencer votre formation ?
            </Typography>
          </FormMessage>

          <RadiosWithAnalytics
            goToNextStep={goToNextStep}
            required
            formControlProps={{
              sx: {
                width: '460px',
              },
            }}
            formControlLabelProps={{
              sx: {
                backgroundColor: 'white',
                padding: '10px',
                borderRadius: '4px',
                margin: '5px',
              },
            }}
            name="startDate"
            data={[
              { label: '🏃 Dès la semaine prochaine', value: 'nextWeek' },
              { label: '🚶‍♂️ Le mois prochain', value: 'nextMonth' },
              { label: "🧘 Plus tard dans l'année", value: 'thisYear' },
            ]}
          />
        </Container>
      </Slide>
      <NextPrevButtonGroup
        nextStep={goToNextStep}
        disableNextButton={formState.hasValidationErrors}
      />
    </Container>
  );
};

export default StartDate;
