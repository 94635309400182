import { Grid, Typography } from '@mui/material';
import React, { ReactElement } from 'react';
import { Formula, FormulaCustomId } from '../entities';

type TableRowProps = {
  selectedFormula: Formula;
  isSmallScreen: boolean;
  chip?: ReactElement;
  title: string;
  content?: string;
  formulaD: ReactElement;
  formulaC: ReactElement;
  formulaB: ReactElement;
};

const FormulaTableRow = (props: TableRowProps) => {
  let formulaToDisplayForMobile: ReactElement;
  switch (props.selectedFormula.customId) {
    case FormulaCustomId.b:
      formulaToDisplayForMobile = props.formulaB;
      break;
    case FormulaCustomId.c:
      formulaToDisplayForMobile = props.formulaC;
      break;
    case FormulaCustomId.d:
      formulaToDisplayForMobile = props.formulaD;
      break;
    default:
      formulaToDisplayForMobile = props.formulaD;
  }
  return (
    <Grid
      container
      direction="row"
      columns={{ xs: 10, md: 12 }}
      sx={{ paddingY: 2, '&:hover': { backgroundColor: 'grey.200' } }}
    >
      <Grid container item xs={9} md={6} direction="column" sx={{ paddingX: 2 }}>
        <Typography component="span">
          {props.chip}
          <b>{props.title}</b>
        </Typography>
        <Typography
          component="span"
          marginTop="12px"
          variant="body2"
          sx={{ marginTop: 0, maxWidth: '660px' }}
        >
          {props.content &&
            props.content.split('\n').map(line => (
              <Typography key={line} sx={{ marginY: 1 }}>
                {line}
              </Typography>
            ))}
        </Typography>
      </Grid>
      {props.isSmallScreen ? (
        <Grid item xs={2}>
          {formulaToDisplayForMobile}
        </Grid>
      ) : (
        <>
          <Grid item sm={2} alignContent="center">
            {props.formulaD}
          </Grid>
          <Grid item sm={2}>
            {props.formulaC}
          </Grid>
          <Grid item sm={2}>
            {props.formulaB}
          </Grid>
        </>
      )}
    </Grid>
  );
};
export default FormulaTableRow;
