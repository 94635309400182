enum TemplateIds {
  'excel_beginner' = '1615284164453x142693760493477490',
  'excel_novice' = '1615284318766x202912274831780860',
  'excel_intermediate' = '1615284536291x982049654111248500',
  'excel_advanced' = '1615284724353x868202160289423900',
  'excelWord_beginner' = '1622040332796x682889000858419200',
  'excelWord_intermediate' = '1622040517131x568640104702148600',
  'outlook_beginner' = '1622040280060x587699637658845200',
  'bureautique_beginner' = '1622040888379x870351923429769200',
}

export default TemplateIds;
