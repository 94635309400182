import { Box, Chip, Container, Grid, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Formula, Skill } from '../entities';
import theme from '../theme';
import excelLogo from '../images/excel_negative.png';
import wordLogo from '../images/word_negative.png';
import outlookLogo from '../images/outlook_negative.png';
import powerpointLogo from '../images/powerpoint_negative.png';
import FormulaTableHeader from './FormulaTableHeader';
import FormulaTableRow from './FormulaTableRow';

type LogoHashTableType = {
  [key: string]: string;
};

const logoHashTable: LogoHashTableType = {
  Excel: excelLogo,
  'Macro excel': excelLogo,
  Word: wordLogo,
  Outlook: outlookLogo,
  PowerPoint: powerpointLogo,
};

type ProgramFormulasProps = {
  formulas: Formula[];
  goToNextStep: (formula: Formula) => void;
  selectedFormula: Formula;
  setSelectedFormula: (formula: Formula) => void;
};

const ProgramFormulas = (props: ProgramFormulasProps) => {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const allSkillsFromTraining = props.formulas
    .reduce((acc: Skill[], formula: Formula) => acc.concat(formula.skills), [])
    .filter((value, index, self) => index === self.findIndex(s => s.id === value.id))
    .sort((a, b) => a.index - b.index);

  return (
    <Container sx={{ padding: 0, margin: isSmallScreen ? 0 : 'auto' }}>
      <Grid container direction="column">
        <FormulaTableHeader
          setSelectedFormula={props.setSelectedFormula}
          formulas={props.formulas}
          goToNextStep={props.goToNextStep}
        />
        <FormulaTableRow
          selectedFormula={props.selectedFormula}
          isSmallScreen={isSmallScreen}
          title="✏️ Heures de cours particuliers"
          formulaD={
            <Typography textAlign="center" color="primary.dark">
              <b>16 h</b>
            </Typography>
          }
          formulaC={
            <Typography textAlign="center" color="primary.dark">
              <b>10 h</b>
            </Typography>
          }
          formulaB={
            <Typography textAlign="center" color="primary.dark">
              <b>8 h</b>
            </Typography>
          }
        />

        <FormulaTableRow
          selectedFormula={props.selectedFormula}
          isSmallScreen={isSmallScreen}
          title="💌 Aide à la demande après la formation"
          content="Un blocage sur un logiciel après votre formation ? Envoyez-nous un mail et on vous rappelle pour vous aider !"
          formulaD={
            <>
              <Typography textAlign="center" color="primary.dark">
                <b>1 an</b>
              </Typography>
              <Typography marginTop="12px" variant="body2" textAlign="center" sx={{ marginTop: 0 }}>
                après la fin de la formation
              </Typography>
            </>
          }
          formulaC={
            <>
              <Typography textAlign="center" color="primary.dark">
                <b>4 mois</b>
              </Typography>
              <Typography marginTop="12px" variant="body2" textAlign="center" sx={{ marginTop: 0 }}>
                après la fin de la formation
              </Typography>
            </>
          }
          formulaB={
            <>
              <Typography textAlign="center" color="primary.dark">
                <b>2 mois</b>
              </Typography>
              <Typography marginTop="12px" variant="body2" textAlign="center" sx={{ marginTop: 0 }}>
                après la fin de la formation
              </Typography>
            </>
          }
        />
        {isSmallScreen && (
          <FormulaTableRow
            selectedFormula={props.selectedFormula}
            isSmallScreen={isSmallScreen}
            title="🪙 Prix de la formation"
            formulaD={
              <Typography textAlign="center" color="primary.dark">
                <b>{props.formulas[2].price.toLocaleString('fr-fr')} €</b>
              </Typography>
            }
            formulaC={
              <Typography textAlign="center" color="primary.dark">
                <b>{props.formulas[1].price.toLocaleString('fr-fr')} €</b>
              </Typography>
            }
            formulaB={
              <Typography textAlign="center" color="primary.dark">
                <b>{props.formulas[0].price.toLocaleString('fr-fr')} €</b>
              </Typography>
            }
          />
        )}
        <FormulaTableRow
          selectedFormula={props.selectedFormula}
          isSmallScreen={isSmallScreen}
          title="🎥 Accès aux replays pendant 2 ans"
          content="Vous avez peur d’oublier une notion pendant une session avec un formateur ? Pas d’inquiétude, si vous le souhaitez, elles sont enregistrées et vous pourrez revenir les voir quand vous voulez. "
          formulaD={
            <Typography textAlign="center" color="primary.dark">
              <b>Inclus</b>
            </Typography>
          }
          formulaC={
            <Typography textAlign="center" color="primary.dark">
              <b>Inclus</b>
            </Typography>
          }
          formulaB={
            <Typography textAlign="center" color="primary.dark">
              <b>Inclus</b>
            </Typography>
          }
        />
        <FormulaTableRow
          selectedFormula={props.selectedFormula}
          isSmallScreen={isSmallScreen}
          title="🎓 Passage de la Certification TOSA ou ENI"
          content="Vous voulez rajouter une ligne sur votre CV pour prouver votre niveau en bureautique ? A l'issue de notre formation, vous pouvez passer gratuitement la certification TOSA ou ENI, les certifications préférées des recruteurs."
          formulaD={
            <Typography textAlign="center" color="primary.dark">
              <b>Inclus</b>
            </Typography>
          }
          formulaC={
            <Typography textAlign="center" color="primary.dark">
              <b>Inclus</b>
            </Typography>
          }
          formulaB={
            <Typography textAlign="center" color="primary.dark">
              <b>Inclus</b>
            </Typography>
          }
        />
        {allSkillsFromTraining.map(s => (
          <FormulaTableRow
            key={s.title}
            selectedFormula={props.selectedFormula}
            isSmallScreen={isSmallScreen}
            chip={
              <Chip
                icon={
                  <img
                    src={logoHashTable[s.tag.Display]}
                    alt="office software logo"
                    height={isSmallScreen ? '20px' : '32px'}
                  />
                }
                label={s.tag.Display}
                sx={{
                  backgroundColor: s.tag.color,
                  color: 'white',
                  borderRadius: '5px',
                  marginRight: 1,
                  [theme.breakpoints.down('lg')]: {
                    fontWeight: 600,
                    fontSize: 8,
                  },
                }}
              />
            }
            title={s.title}
            content={s.content}
            formulaD={
              <Box sx={{ width: '30px', margin: 'auto' }}>
                {props.formulas[2].skills.includes(s) ? (
                  <CheckCircleIcon fontSize="large" color="primary" />
                ) : (
                  <Box sx={{ color: 'grey.400' }}>
                    <CancelIcon fontSize="large" />
                  </Box>
                )}
              </Box>
            }
            formulaC={
              <Box sx={{ width: '30px', margin: 'auto' }}>
                {props.formulas[1].skills.includes(s) ? (
                  <CheckCircleIcon fontSize="large" color="primary" />
                ) : (
                  <Box sx={{ color: 'grey.400' }}>
                    <CancelIcon fontSize="large" />
                  </Box>
                )}
              </Box>
            }
            formulaB={
              <Box sx={{ width: '30px', margin: 'auto' }}>
                {props.formulas[0].skills.includes(s) ? (
                  <CheckCircleIcon fontSize="large" color="primary" />
                ) : (
                  <Box sx={{ color: 'grey.400' }}>
                    <CancelIcon fontSize="large" />
                  </Box>
                )}
              </Box>
            }
          />
        ))}
      </Grid>
    </Container>
  );
};

export default ProgramFormulas;
