import { Button, AppBar, Toolbar, Grid, useMediaQuery } from '@mui/material';
import React from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import theme from '../../theme';

type NextPrevButtonGroupType = {
  nextStep: () => void;
  nextButtonType?: 'button' | 'submit' | 'reset';
  disableNextButton?: boolean;
  nextButtonText?: string;
  forceMobileDisplay?: boolean;
};

const NextPrevButtonGroup = ({
  nextStep,
  nextButtonType = 'submit',
  disableNextButton = false,
  nextButtonText = 'suivant',
  forceMobileDisplay = false,
}: NextPrevButtonGroupType) => {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  if (isSmallScreen && !forceMobileDisplay) return <></>;
  return (
    <AppBar
      position="fixed"
      color="transparent"
      sx={{ top: 'auto', bottom: 0, zIndex: -1 }}
      elevation={0}
    >
      <Toolbar
        sx={{
          margin: 'auto',
          backgroundColor: 'transparent',
          height: '10vh',
          width: '80vw',
          alignItems: 'flex-start',
          [theme.breakpoints.down('md')]: {
            alignItems: 'center',
            verticalAlign: 'middle',
            backgroundColor: 'white',
            width: '100%',
            height: '70px',
          },
        }}
      >
        <Grid
          container
          wrap="nowrap"
          flexDirection="row-reverse"
          spacing={{ xs: 0, md: 0 }}
          columns={{ xs: 2, sm: 2, md: 2 }}
        >
          <Grid item xs={1} md={1}>
            <Button
              data-heap-nav-button="next"
              type={nextButtonType || 'button'}
              disabled={disableNextButton}
              disableElevation
              color="success"
              sx={{
                float: 'right',
                color: 'grey.50',
                height: theme.spacing(6),
                [`&.Mui-disabled`]: {
                  backgroundColor: 'success.main',
                  color: '#F6FFED',
                  opacity: '0.40',
                },
              }}
              variant="contained"
              onClick={nextStep}
            >
              {nextButtonText} <ArrowForwardIcon sx={{ paddingLeft: 1 }} />
            </Button>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default NextPrevButtonGroup;
