import React, { useEffect, useState } from 'react';
import StepWizard from 'react-step-wizard';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/system';
import { Loader, ProgramHeader } from '../components';
import { FAQItem, Formula, FormulaCustomId, Objective, Topic } from '../entities';
import { getFAQItems, getTraining } from '../http/bubble';
import theme from '../theme';
import {
  ProgramConfirmationPage,
  ProgramDatePage,
  ProgramSinglePage,
} from '../components/ProgramPages';

type PageParams = {
  trainingId: string;
};

const ProgramPage = () => {
  const { trainingId } = useParams<PageParams>();
  const [selectedFormula, setSelectedFormula] = useState<Formula>();
  const [training, setTraining] =
    useState<{ formulas: Formula[]; topic: Topic; objective: Objective }>();
  // Exceptionnaly using any type as a workaround for the incorrectly typed instance passed by the react-wizard-stepper librairy
  // https://github.com/jcmcneal/react-step-wizard/issues/103
  // https://github.com/jcmcneal/react-step-wizard/issues/91
  const [wizardInstance, setWizardInstance] = useState<any>();
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [faqItems, setFaqItems] = useState<FAQItem[]>([]);

  useEffect(() => {
    document.title = 'Atoria | Votre formation';
  }, []);

  useEffect(() => {
    async function bubbleApiCall() {
      const trainingFromBuble = await getTraining(trainingId);
      setTraining(trainingFromBuble);
      setFirstName(trainingFromBuble.learner.firstName);
      setLastName(trainingFromBuble.learner.lastName);
      setEmail(trainingFromBuble.learner.email);
      setPhoneNumber(trainingFromBuble.learner.phoneNumber);
      const faqItemsFromBubble = await getFAQItems();
      setFaqItems(faqItemsFromBubble);
      setSelectedFormula(
        trainingFromBuble.formulas.find((f: Formula) => f.customId === FormulaCustomId.d),
      );
      /* Handle errors */
    }
    bubbleApiCall();
  }, [trainingId]);

  if (!training || !selectedFormula) {
    return <Loader />;
  }
  return (
    <Box sx={{ width: '100%', overflowX: 'clip', backgroundColor: 'white' }}>
      <ProgramHeader
        elevation={2}
        position="fixed"
        activeStep={wizardInstance?.state.activeStep}
        goToNextStep={() => wizardInstance.nextStep()}
      />
      <Box
        sx={{
          marginY: '100px',
          [theme.breakpoints.down('md')]: {
            marginY: '56px',
          },
        }}
      >
        <StepWizard
          isHashEnabled
          isLazyMount
          transitions={{}}
          instance={(instance: any) => setWizardInstance(instance)}
        >
          <ProgramSinglePage
            formulas={training.formulas}
            objective={training.objective}
            topic={training.topic}
            selectedFormula={selectedFormula}
            setSelectedFormula={setSelectedFormula}
            stepName="landing"
            hashKey="devis"
            faqItems={faqItems}
            firstName={firstName}
          />
          <ProgramDatePage
            stepName="signup"
            hashKey="inscription"
            email={email}
            trainingId={trainingId}
            phoneNumber={phoneNumber}
            fullName={`${firstName} ${lastName}`}
            selectedFormula={selectedFormula}
          />
          <ProgramConfirmationPage
            stepName="confirmation"
            hashKey="confirmation"
            selectedFormula={selectedFormula}
            trainingId={trainingId}
          />
        </StepWizard>
      </Box>
    </Box>
  );
};

export default ProgramPage;
