import { Grid, useTheme } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { Form } from 'react-final-form';
import StepWizard from 'react-step-wizard';
import { useLocation } from 'react-router';
import { Header, Loader } from '../components';
import {
  SelfAssessedLevel,
  ComputerUsage,
  SkillsKnowledge,
  Objective,
  StartDate,
  ComputerOwnership,
  TopicChoice,
  Generation,
  EmailInfo,
  ContactInfo,
} from '../components/FormPages';
import { FormAnswer, validateTopic } from '../entities';
import backgroundLogo from '../images/Logo-Trans-BG-SVG.svg';
import { updateLearnerContactInfo } from '../http/bubble';
import formatNameCase from '../utils/stringManipulation';
import { updateLeadOnCRM } from '../http/pipedream';

type FormComponentProps = {
  onSubmit: (formAnswers: FormAnswer) => {};
  initialValues: FormAnswer | {};
};

const FormComponent = (props: FormComponentProps) => (
  <Form onSubmit={props.onSubmit} initialValues={props.initialValues}>
    {formProps => (
      <form onSubmit={formProps.handleSubmit}>
        <StepWizard isHashEnabled isLazyMount transitions={{}}>
          <TopicChoice stepName="topicChoice" hashKey="thematique" />
          <SelfAssessedLevel stepName="selfAssessedLevel" hashKey="usage" />
          <ComputerUsage stepName="computerUsage" hashKey="utilisation informatique" />
          <SkillsKnowledge stepName="skillKnowledge" hashKey="connaissances" />
          <Objective stepName="objective" hashKey="objectif" />
          <StartDate stepName="startDate" hashKey="planification" />
          <ComputerOwnership stepName="computerOwnership" hashKey="ordinateur" />
          <Generation stepName="generation" hashKey="generation" />
          <EmailInfo stepName="email" hashKey="email" />
          <ContactInfo stepName="contactInfo" hashKey="contact" />
        </StepWizard>
      </form>
    )}
  </Form>
);

const FormPage = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const topicFromParams = searchParams.get('topic');
  const analytics = {
    utm_source: searchParams.get('utm_source'),
    utm_medium: searchParams.get('utm_medium'),
    utm_term: searchParams.get('utm_term'),
    utm_content: searchParams.get('utm_content'),
    utm_campaign: searchParams.get('utm_campaign'),
    msclkid: searchParams.get('msclkid'),
    gclid: searchParams.get('gclid'),
    fbclid: searchParams.get('fbclid'),
  };

  let initialValues = {};
  try {
    if (topicFromParams)
      initialValues = {
        topic: validateTopic(topicFromParams),
      };
  } catch (e: any) {
    searchParams.delete('topic');
    window.location.assign(`questionnaire?${searchParams}`);
    throw new Error(e);
  }

  const onSubmit = async (rawFormAnswers: FormAnswer) => {
    setIsSubmitting(true);
    const formAnswers: FormAnswer = {
      ...rawFormAnswers,
      phoneNumber: rawFormAnswers.phoneNumber.startsWith('0')
        ? `+33${rawFormAnswers.phoneNumber.slice(1)}`
        : rawFormAnswers.phoneNumber,
      firstname: formatNameCase(rawFormAnswers.firstname),
      lastname: formatNameCase(rawFormAnswers.lastname),
    };

    const updateData = {
      firstName: formAnswers.firstname,
      lastName: formAnswers.lastname,
      phoneNumber: formAnswers.phoneNumber,
      email: formAnswers.email,
      analytics,
    };

    const trainingId = await updateLearnerContactInfo(updateData);
    await updateLeadOnCRM(updateData);

    window.location.assign(`programme/${trainingId}?${searchParams.toString()}`);
  };

  useEffect(() => {
    document.title = 'Atoria | Obtenez votre devis';
  }, []);

  const theme = useTheme();
  return (
    <Grid
      sx={{
        backgroundColor: theme.palette.mode === 'dark' ? 'primary.dark' : 'grey.100',
        height: '100vh',
        maxWidth: '100%',
        overflowX: 'hidden',
        [theme.breakpoints.up('xl')]: {
          '&::before': {
            content: "''",
            position: 'fixed',
            width: '100%',
            height: '100%',
            backgroundImage: `url(${backgroundLogo})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '30%',
            backgroundPosition: 'bottom -100px left -100px',
            opacity: theme.palette.mode === 'dark' ? '0.3' : '0.07',
          },
        },
        [theme.breakpoints.up('md')]: {
          '&::before': {
            content: "''",
            position: 'fixed',
            width: '100%',
            height: '100%',
            backgroundImage: `url(${backgroundLogo})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '30%',
            backgroundPosition: 'bottom -70px left -70px',
            opacity: theme.palette.mode === 'dark' ? '0.3' : '0.07',
          },
        },
        [theme.breakpoints.down('md')]: {
          height: 'calc(100vh - 70px)',
          '&::before': {
            content: "''",
            position: 'fixed',
            width: '100%',
            height: '100%',
          },
        },
      }}
    >
      <Header elevation={theme.palette.mode === 'dark' ? 0 : 4} />
      {isSubmitting ? (
        <Loader />
      ) : (
        <FormComponent onSubmit={onSubmit} initialValues={initialValues} />
      )}
    </Grid>
  );
};

export default FormPage;
