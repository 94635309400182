import { Container, Slide, Typography } from '@mui/material';
import React from 'react';
import { StepWizardChildProps } from 'react-step-wizard';
import { useForm } from 'react-final-form';
import { NextPrevButtonGroup, RadiosWithAnalytics } from '../inputs';
import FormMessage from '../FormMessage';
import { FormAnswer } from '../../entities';
import Loader from '../Loader';

const Objective: React.FC<Partial<StepWizardChildProps>> = props => {
  const form = useForm<FormAnswer>('useFormState');
  let formState = form.getState();
  const { selfAssessedLevel } = formState.values;
  if (props.goToNamedStep && !selfAssessedLevel) {
    props.goToNamedStep('topicChoice');
    return <Loader />;
  }

  const goToNextStep = () => {
    formState = form.getState();
    if (props.goToNamedStep && !formState.hasValidationErrors) {
      props.goToNamedStep('startDate');
    }
  };

  return (
    <Container
      maxWidth="lg"
      sx={{
        height: '65vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingTop: 2,
      }}
    >
      <Slide timeout={1200} direction="left" in mountOnEnter unmountOnExit>
        <Container
          sx={{
            height: '40vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
          maxWidth="md"
        >
          <FormMessage>
            <Typography marginBottom="20px" color="grey.100">
              Nos formations sont des cours particuliers avec un formateur dédié. En savoir plus sur
              vos objectifs lui permettra d&apos;adapter ses exercices.
            </Typography>
            <Typography color="grey.50" variant="h1" fontSize="24px">
              Quelle raison principale vous pousse à vouloir faire cette formation ?
            </Typography>
          </FormMessage>
          <RadiosWithAnalytics
            goToNextStep={goToNextStep}
            required
            formControlProps={{
              sx: {
                width: '500px',
              },
            }}
            formControlLabelProps={{
              sx: {
                backgroundColor: 'white',
                padding: '10px',
                borderRadius: '4px',
                margin: '5px',
              },
            }}
            label=""
            name="objective"
            data={[
              {
                label: '💪 Je suis en poste, je veux améliorer ma productivité',
                value: 'improveProductivity',
              },
              { label: '💼 Je commence bientôt un nouveau poste', value: 'changeJob' },
              { label: '✏️ Je cherche un nouvel emploi ', value: 'searchJob' },
              { label: '❓ Autre', value: 'other' },
            ]}
          />
        </Container>
      </Slide>
      <NextPrevButtonGroup
        nextStep={goToNextStep}
        disableNextButton={formState.hasValidationErrors}
      />
    </Container>
  );
};

export default Objective;
