import Formula from '../Formula';
import FormulaCustomId from './FormulaCustomId';
import Topic from './Topic';

const EXCEL_LINKS: Record<FormulaCustomId, string> = {
  'Formula A':
    'https://www.moncompteformation.gouv.fr/espace-prive/html/#/formation/recherche/84109852800026_Atoria_FEOC/84109852800026_Atoria_FEOC24?contexteFormation=ACTIVITE_PROFESSIONNELLE',
  'Formula B':
    'https://www.moncompteformation.gouv.fr/espace-prive/html/#/formation/recherche/84109852800026_Atoria_FBE/84109852800026_Atoria_FBE8?contexteFormation=ACTIVITE_PROFESSIONNELLE',
  'Formula C':
    'https://www.moncompteformation.gouv.fr/espace-prive/html/#/formation/recherche/84109852800026_Atoria_FEOC/84109852800026_Atoria_FEOC10?contexteFormation=ACTIVITE_PROFESSIONNELLE',
  'Formula D':
    'https://www.moncompteformation.gouv.fr/espace-prive/html/#/formation/recherche/84109852800026_Atoria_FEOC/84109852800026_Atoria_FEOC16?contexteFormation=ACTIVITE_PROFESSIONNELLE',
};

const WORD_EXCEL_LINKS: Record<FormulaCustomId, string> = {
  'Formula A':
    'https://www.moncompteformation.gouv.fr/espace-prive/html/#/formation/recherche/84109852800026_Atoria_FEOC/84109852800026_Atoria_FEOC24?contexteFormation=ACTIVITE_PROFESSIONNELLE',
  'Formula B':
    'https://www.moncompteformation.gouv.fr/espace-prive/html/#/formation/recherche/84109852800026_Atoria_FEOC/84109852800026_Atoria_FEOC8?contexteFormation=ACTIVITE_PROFESSIONNELLE',
  'Formula C':
    'https://www.moncompteformation.gouv.fr/espace-prive/html/#/formation/recherche/84109852800026_Atoria_FEOC/84109852800026_Atoria_FEOC10?contexteFormation=ACTIVITE_PROFESSIONNELLE',
  'Formula D':
    'https://www.moncompteformation.gouv.fr/espace-prive/html/#/formation/recherche/84109852800026_Atoria_FEOC/84109852800026_Atoria_FEOC16?contexteFormation=ACTIVITE_PROFESSIONNELLE',
};

const OUTLOOK_LINKS: Record<FormulaCustomId, string> = {
  'Formula A':
    'https://www.moncompteformation.gouv.fr/espace-prive/html/#/formation/recherche/84109852800018_pack-office-v1/84109852800018_impact-v1',
  'Formula B':
    'https://www.moncompteformation.gouv.fr/espace-prive/html/#/formation/recherche/84109852800018_bureautique-eni-v1/84109852800018_basique-v1',
  'Formula C':
    'https://www.moncompteformation.gouv.fr/espace-prive/html/#/formation/recherche/84109852800018_bureautique-eni-v1/84109852800018_complete-v1',
  'Formula D':
    'https://www.moncompteformation.gouv.fr/espace-prive/html/#/formation/recherche/84109852800018_bureautique-eni-v1/84109852800018_la-totale-v1',
};

const OFFICE_LINKS: Record<FormulaCustomId, string> = {
  'Formula A':
    'https://www.moncompteformation.gouv.fr/espace-prive/html/#/formation/recherche/84109852800026_Atoria_FEOC/84109852800026_Atoria_FEOC24?contexteFormation=ACTIVITE_PROFESSIONNELLE',
  'Formula B':
    'https://www.moncompteformation.gouv.fr/espace-prive/html/#/formation/recherche/84109852800026_Atoria_FEOC/84109852800026_Atoria_FEOC8?contexteFormation=ACTIVITE_PROFESSIONNELLE',
  'Formula C':
    'https://www.moncompteformation.gouv.fr/espace-prive/html/#/formation/recherche/84109852800026_Atoria_FEOC/84109852800026_Atoria_FEOC10?contexteFormation=ACTIVITE_PROFESSIONNELLE',
  'Formula D':
    'https://www.moncompteformation.gouv.fr/espace-prive/html/#/formation/recherche/84109852800026_Atoria_FEOC/84109852800026_Atoria_FEOC16?contexteFormation=ACTIVITE_PROFESSIONNELLE',
};

const getformulaValues = (customId: FormulaCustomId): Formula => {
  switch (customId) {
    case 'Formula A': {
      return {
        title: 'Impact',
        skills: [],
        price: 580,
        hours: 5,
        mfcLink: '',
        helpDuration: '1 mois',
        numberOfExercices: '6',
        customId: FormulaCustomId.a,
      };
    }
    case 'Formula B': {
      return {
        title: 'Basique',
        skills: [],
        price: 880,
        hours: 8,
        mfcLink: '',
        helpDuration: '2 mois',
        numberOfExercices: '10',
        customId: FormulaCustomId.b,
      };
    }
    case 'Formula C': {
      return {
        title: 'Complète',
        skills: [],
        price: 1150,
        hours: 10,
        mfcLink: '',
        helpDuration: '4 mois',
        numberOfExercices: '20',
        customId: FormulaCustomId.c,
      };
    }
    case 'Formula D': {
      return {
        title: 'La Totale',
        skills: [],
        price: 1600,
        hours: 16,
        mfcLink: '',
        helpDuration: '1 an',
        numberOfExercices: 'illimité',
        customId: FormulaCustomId.d,
      };
    }
    default:
      throw new Error('Could not find formula values');
  }
};

const getLinksForTopic = (topic: Topic): Record<FormulaCustomId, string> => {
  switch (topic) {
    case 'Excel': {
      return EXCEL_LINKS;
    }
    case 'La bureautique': {
      return OFFICE_LINKS;
    }
    case 'Outlook': {
      return OUTLOOK_LINKS;
    }
    case 'Excel & Word': {
      return WORD_EXCEL_LINKS;
    }
    default:
      throw new Error('Could not find Mfc links values');
  }
};

const getFormulaValues = (customId: FormulaCustomId, topic: Topic): Formula => {
  const formulaValues = getformulaValues(customId);
  const mfcLinks = getLinksForTopic(topic);
  return { ...formulaValues, mfcLink: mfcLinks[customId] };
};

export default getFormulaValues;
