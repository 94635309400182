import { styled, Tab, Tabs } from '@mui/material';
import React, { Children, ReactChild, ReactFragment, ReactPortal } from 'react';

const StyledTabs = styled(Tabs)({
  '& .MuiTabs-indicator': {
    display: 'none',
  },
});

interface StyledTabProps {
  label: ReactChild | ReactFragment | ReactPortal;
  key: number;
}

const StyledTab = styled((props: StyledTabProps) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    border: '1px solid #e9e9e9',
    borderTop: `2px solid ${theme.palette.grey[200]}`,
    borderLeft: `2px solid ${theme.palette.grey[200]}`,
    borderRight: `2px solid ${theme.palette.grey[200]}`,
    borderBottom: `2px solid ${theme.palette.grey[600]}`,
    borderRadius: '5px 5px 0px 0px',
    background: '#f7f7f7',
    opacity: 1,
    textTransform: 'none',
    '&.Mui-selected': {
      color: '#1890ff',
      fontWeight: theme.typography.fontWeightMedium,
      borderTop: `2px solid ${theme.palette.grey[600]}`,
      borderLeft: `2px solid ${theme.palette.grey[600]}`,
      borderRight: `2px solid ${theme.palette.grey[600]}`,
      borderBottom: `0px solid white`,
      backgroundColor: 'white',
      '& $wrapper': {
        opacity: 1,
      },
      '& .MuiChip-root': {
        backgroundColor: theme.palette.primary.dark,
      },
    },
    '&.Mui-focusVisible': {
      backgroundColor: '#d1eaff',
    },
  }),
);

type FormulaTabsProps = {
  selectedTabIndex: number;
  setSelectedTabIndex: (e: React.SyntheticEvent, index: number) => void;
};

const FormulaTabs: React.FC<FormulaTabsProps> = props => {
  const childrenArray = Children.toArray(props.children);

  return (
    <StyledTabs
      variant="fullWidth"
      value={props.selectedTabIndex}
      onChange={props.setSelectedTabIndex}
    >
      {childrenArray.map((t, i) => (
        // Disabling the rule exceptionnaly because all 3 conditions to use indexes are met
        // see: https://robinpokorny.medium.com/index-as-a-key-is-an-anti-pattern-e0349aece318#:~:text=Update%3A%20Exception%20from%20the%20rule
        // eslint-disable-next-line react/no-array-index-key
        <StyledTab key={i} label={t} />
      ))}
    </StyledTabs>
  );
};

export default FormulaTabs;
