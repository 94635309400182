import { createTheme, responsiveFontSizes } from '@mui/material';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';

// Adding new palette color shades
declare module '@mui/material/styles' {
  interface PaletteColor {
    darker?: string;
    lighter?: string;
  }
  interface SimplePaletteColorOptions {
    darker?: string;
    lighter?: string;
  }
}

// Adding new Typography variants
declare module '@mui/material/styles' {
  interface TypographyVariants {
    h1Program: React.CSSProperties;
    h2Program: React.CSSProperties;
    body3: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    h1Program?: React.CSSProperties;
    h2Program?: React.CSSProperties;
    body3?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h1Program: true;
    h2Program: true;
    body3: true;
  }
}

// eslint-disable-next-line import/no-mutable-exports
let darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      lighter: '#F4F9FD',
      light: '#69c0ff',
      main: '#1890FF',
      dark: '#0050b3',
      darker: '#001529',
    },
    secondary: {
      lighter: '#FFF7E6',
      light: '#FFC069',
      main: '#FA8C16',
      dark: '#AD4E00',
      darker: '#873800',
    },
    success: {
      lighter: '#F6FFED',
      light: '#95DE64',
      main: '#52C41A',
      dark: '#237804',
      darker: '#092B00',
    },
    error: {
      main: '#ff7875',
    },
  },
});

darkTheme = responsiveFontSizes(
  createTheme(darkTheme, {
    typography: {
      fontFamily: ['Ubuntu', 'Helvetica', 'sans-serif'].join(','),
      h1: {
        fontSize: 24,
        fontWeight: 400,
        color: darkTheme.palette.grey[50],
      },
      h2: {
        fontSize: 18,
        fontWeight: 400,
        color: '#d9d9d9',
      },
      h3: {
        fontSize: 18,
        fontWeight: 600,
        marginBottom: darkTheme.spacing(1),
        color: darkTheme.palette.primary.darker,
        [darkTheme.breakpoints.down('md')]: {
          fontSize: 14,
        },
      },
      body1: {
        color: darkTheme.palette.primary.darker,
        fontSize: 16,
      },
      body2: {
        color: darkTheme.palette.grey[600],
        fontSize: 16,
      },
      body3: {
        ...darkTheme.typography.body2,
        color: darkTheme.palette.grey[50],
        fontSize: 12,
        display: 'block',
        fontFamily: ['Ubuntu', 'Helvetica', 'sans-serif'].join(','),
      },
      h1Program: {
        textAlign: 'center',
        maxWidth: '100%',
        fontSize: 24,
        fontWeight: 400,
        color: darkTheme.palette.primary.darker,
        margin: '24px 12px',
        display: 'block',
        fontFamily: ['Ubuntu', 'Helvetica', 'sans-serif'].join(','),
        [darkTheme.breakpoints.down('md')]: {
          margin: 0,
        },
      },
      h2Program: {
        fontSize: 18,
        fontWeight: 700,
        display: 'block',
        textAlign: 'center',
        color: darkTheme.palette.primary.darker,
        fontFamily: ['Ubuntu', 'Helvetica', 'sans-serif'].join(','),
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            backgroundColor: 'white',
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            color: darkTheme.palette.grey[700],
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: darkTheme.palette.grey[50],
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            marginBottom: '20px',
            borderColor: darkTheme.palette.grey[50],
            color: darkTheme.palette.grey[50],
            [`:hover .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: darkTheme.palette.grey[50],
            },
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: darkTheme.palette.grey[50],
              color: darkTheme.palette.grey[50],
            },
          },
        },
      },
    },
  }),
);

// eslint-disable-next-line import/no-mutable-exports
let lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      lighter: '#F4F9FD',
      light: '#69c0ff',
      main: '#1890FF',
      dark: '#0050b3',
      darker: '#001529',
    },
    secondary: {
      lighter: '#FFF7E6',
      light: '#FFC069',
      main: '#FA8C16',
      dark: '#AD4E00',
      darker: '#873800',
    },
    success: {
      lighter: '#F6FFED',
      light: '#95DE64',
      main: '#52C41A',
      dark: '#237804',
      darker: '#092B00',
    },
    error: {
      main: '#ff7875',
    },
  },
});

lightTheme = responsiveFontSizes(
  createTheme(lightTheme, {
    typography: {
      fontFamily: ['Ubuntu', 'Helvetica', 'sans-serif'].join(','),
      h1: {
        fontSize: 24,
        fontWeight: 400,
        color: lightTheme.palette.primary.darker,
      },
      h2: {
        fontSize: 18,
        fontWeight: 400,
        color: '#d9d9d9',
      },
      h3: {
        fontSize: 18,
        fontWeight: 600,
        marginBottom: lightTheme.spacing(1),
        color: lightTheme.palette.primary.darker,
        [lightTheme.breakpoints.down('md')]: {
          fontSize: 14,
        },
      },
      body1: {
        color: lightTheme.palette.primary.darker,
        fontSize: 16,
      },
      body2: {
        color: lightTheme.palette.grey[600],
        fontSize: 16,
      },
      body3: {
        ...lightTheme.typography.body2,
        color: lightTheme.palette.primary.darker,
        fontSize: 12,
        display: 'block',
        fontFamily: ['Ubuntu', 'Helvetica', 'sans-serif'].join(','),
      },
      h1Program: {
        maxWidth: '100%',
        fontSize: 24,
        fontWeight: 400,
        color: lightTheme.palette.primary.darker,
        textAlign: 'center',
        margin: '24px 12px',
        display: 'block',
        fontFamily: ['Ubuntu', 'Helvetica', 'sans-serif'].join(','),
        [lightTheme.breakpoints.down('md')]: {
          marginX: 0,
        },
      },
      h2Program: {
        fontSize: 18,
        fontWeight: 700,
        display: 'block',
        textAlign: 'center',
        color: lightTheme.palette.primary.darker,
        fontFamily: ['Ubuntu', 'Helvetica', 'sans-serif'].join(','),
      },
    },
    components: {
      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            border: `1px solid ${lightTheme.palette.grey[500]}`,
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: lightTheme.palette.grey[500],
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            background: 'white',
            marginBottom: '20px',
            color: lightTheme.palette.primary.main,
            borderColor: `${lightTheme.palette.grey[500]}`,
            [`:hover .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: lightTheme.palette.grey[500],
            },
            [`& .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: lightTheme.palette.grey[500],
              color: lightTheme.palette.grey[300],
            },
          },
        },
      },
    },
  }),
);

export const fullTheme = {
  dark: darkTheme,
  light: lightTheme,
};

const theme = darkTheme;

export default theme;
