import { Container, Slide, Typography } from '@mui/material';
import React from 'react';
import { useForm } from 'react-final-form';
import { StepWizardChildProps } from 'react-step-wizard';
import { NextPrevButtonGroup, RadiosWithAnalytics } from '../inputs';
import FormMessage from '../FormMessage';
import { FormAnswer } from '../../entities';
import TopicSentences from './types/TopicSentences';
import Loader from '../Loader';

const topiSentences: TopicSentences = {
  Excel: 'Excel',
  Outlook: 'Outlook',
  'Excel & Word': 'Word et Excel',
  'La bureautique': 'bureautique',
};

const ComputerUsage: React.FC<Partial<StepWizardChildProps>> = props => {
  const form = useForm<FormAnswer>('useFormState');
  let formState = form.getState();
  const { selfAssessedLevel, topic } = formState.values;
  if (props.goToNamedStep && !selfAssessedLevel) {
    props.goToNamedStep('topicChoice');
    return <Loader />;
  }

  const goToNextStep = () => {
    formState = form.getState();
    if (props.goToNamedStep && !formState.hasValidationErrors) {
      props.goToNamedStep('objective');
    }
  };

  return (
    <Container
      maxWidth="lg"
      sx={{
        height: '65vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingTop: 2,
      }}
    >
      <Slide timeout={1200} direction="left" in mountOnEnter unmountOnExit>
        <Container
          sx={{
            height: '40vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
          maxWidth="md"
        >
          <FormMessage>
            <Typography marginBottom="20px" color="grey.100">
              C&lsquo;est noté, rassurez-vous nous avons des dizaines de débutants en{' '}
              {topiSentences[topic]} qui ont déjà suivi nos formations
            </Typography>
            <Typography color="grey.50" variant="h1" fontSize="24px">
              À quelle fréquence utilisez-vous un ordinateur ?
            </Typography>
          </FormMessage>
          <RadiosWithAnalytics
            goToNextStep={goToNextStep}
            required
            formControlLabelProps={{
              sx: {
                backgroundColor: 'white',
                padding: '10px',
                borderRadius: '4px',
                margin: '5px',
              },
            }}
            name="computerUsage"
            data={[
              { label: 'Une fois par semaine', value: 'weekly' },
              { label: 'Une fois par jour', value: 'daily' },
              { label: 'Plusieurs fois par jour', value: 'multiDaily' },
            ]}
          />
        </Container>
      </Slide>
      <NextPrevButtonGroup
        nextStep={goToNextStep}
        disableNextButton={formState.hasValidationErrors}
      />
    </Container>
  );
};

export default ComputerUsage;
