import {
  Container,
  InputAdornment,
  Box,
  Slide,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React from 'react';
import { StepWizardChildProps } from 'react-step-wizard';
import { showErrorOnBlur, TextField } from 'mui-rff';
import FaceIcon from '@mui/icons-material/Face';
import PhoneIcon from '@mui/icons-material/Phone';
import { useForm } from 'react-final-form';
import { FieldState } from 'final-form';
import { FormMessage, ReassuranceElementsFormPage } from '..';
import { NextPrevButtonGroup } from '../inputs';
import { FormAnswer } from '../../entities';
import Loader from '../Loader';

const ContactInfo: React.FC<Partial<StepWizardChildProps>> = props => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const form = useForm<FormAnswer>('useFormState');
  const formState = form.getState();
  const { selfAssessedLevel } = formState.values;
  if (props.goToNamedStep && !selfAssessedLevel) {
    props.goToNamedStep('topicChoice');
    return <Loader />;
  }

  const isFormValid =
    formState.values.firstname &&
    formState.values.lastname &&
    formState.values.phoneNumber &&
    !formState.hasValidationErrors;

  const validatePhoneNumber = (
    value: string,
    allValue: Object,
    meta: FieldState<string> | undefined,
  ) => {
    if (meta?.value) {
      if (value.length !== 10) {
        return 'Votre numéro de téléphone doit contenir 10 chiffres';
      }
    }
    return undefined;
  };

  return (
    <Container
      maxWidth="lg"
      sx={{
        height: '65vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingTop: 2,
      }}
    >
      <Slide timeout={1200} direction="left" in mountOnEnter unmountOnExit>
        <Container
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
          maxWidth="md"
        >
          <FormMessage sx={{ paddingBottom: '40px' }}>
            <Typography marginBottom="24px" color="grey.100">
              Et pour finir quel sont vos nom, prénom et numéro de téléphone ?
              <br />
            </Typography>
            <Typography color="grey.100">
              <b>Aucune revente, aucun spam promis 🙏 !</b>
            </Typography>
          </FormMessage>
          <Box
            sx={{
              flexGrow: 1,
            }}
          >
            <Container
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around',
                alignItems: 'center',
              }}
              maxWidth="sm"
            >
              <Container
                sx={{
                  display: 'flex',
                  flexDirection: isSmallScreen ? 'column' : 'row',
                  paddingX: '0px !important',
                }}
                maxWidth="sm"
              >
                <TextField
                  sx={{
                    marginRight: '10px',
                  }}
                  label="Votre prénom"
                  name="firstname"
                  required
                  data-heap-textfield="firstname"
                  fieldProps={{
                    validateFields: [],
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <FaceIcon
                          sx={{ color: theme.palette.mode === 'dark' ? 'grey.50' : 'grey.500' }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  label="Et votre nom"
                  name="lastname"
                  required
                  data-heap-textfield="lastname"
                  fieldProps={{
                    validateFields: [],
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <FaceIcon
                          sx={{ color: theme.palette.mode === 'dark' ? 'grey.50' : 'grey.500' }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Container>
              <TextField
                label="Votre numéro de téléphone"
                name="phoneNumber"
                type="tel"
                required
                data-heap-textfield="phoneNumber"
                showError={showErrorOnBlur}
                fieldProps={{
                  validate: validatePhoneNumber,
                  validateFields: [],
                }}
                InputProps={{
                  placeholder: '0601020304',
                  endAdornment: (
                    <InputAdornment position="end">
                      <PhoneIcon
                        sx={{ color: theme.palette.mode === 'dark' ? 'grey.50' : 'grey.500' }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </Container>
          </Box>

          <Box paddingTop={5}>
            <ReassuranceElementsFormPage />
          </Box>
        </Container>
      </Slide>
      <NextPrevButtonGroup
        nextStep={() => {}}
        disableNextButton={!isFormValid}
        nextButtonType="submit"
        nextButtonText="VOIR MON DEVIS"
        forceMobileDisplay
      />
    </Container>
  );
};
export default ContactInfo;
