import React from 'react';
import {
  FormControlLabel,
  FormControlLabelProps,
  Radio,
  RadioGroup,
  RadioProps,
  styled,
  Typography,
  useRadioGroup,
} from '@mui/material';
import { Field, FieldRenderProps } from 'react-final-form';

interface StyledFormControlLabelProps extends FormControlLabelProps {
  checked: boolean;
}

const StyledFormControlLabel = styled((props: StyledFormControlLabelProps) => (
  <FormControlLabel {...props} />
))(({ theme, value, checked }) => {
  const baseStyling = {
    backgroundColor: theme.palette.grey[50],
    margin: '5px',
    borderRadius: '4px',
    padding: '4px',
    paddingBottom: '22px',
    [`& .MuiTypography-root`]: {
      lineHeight: 1,
    },
  };

  let additionnalStyling = {};
  if (value === 'skillKnown' && checked) {
    additionnalStyling = {
      backgroundColor: '#F6FFED',
      color: theme.palette.success.main,
      border: `2px solid ${theme.palette.success.main}`,
      '.MuiFormControlLabel-label': {
        fontWeight: 'bold',
      },
    };
  } else if (value === 'skillUnknown' && checked) {
    additionnalStyling = {
      backgroundColor: '#FFF7E6',
      color: theme.palette.secondary.main,
      border: `2px solid ${theme.palette.secondary.main}`,
      '.MuiFormControlLabel-label': {
        fontWeight: 'bold',
      },
    };
  }
  return { ...baseStyling, ...additionnalStyling };
});

const StyledRadioInput = (props: FormControlLabelProps) => {
  const radioGroup = useRadioGroup();

  let checked = false;

  if (radioGroup) {
    checked = radioGroup.value === props.value;
  }

  return <StyledFormControlLabel checked={checked} {...props} />;
};

type SkillKnowledgeInputProps = {
  name: string;
  goToNextStep: () => void;
};

const SkillKnowledgeInput = (props: SkillKnowledgeInputProps) => {
  const handleClick = () => {
    setTimeout(() => props.goToNextStep(), 100);
  };

  return (
    <RadioGroup row sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap' }}>
      <StyledRadioInput
        onClick={handleClick}
        data-heap-skillknown={props.name}
        name={props.name}
        value="skillKnown"
        label="Je sais faire"
        labelPlacement="bottom"
        sx={{
          flexBasis: '50%',
        }}
        control={
          <Field
            validate={value => (value ? undefined : 'Required')}
            name={props.name}
            type="radio"
            render={(fieldProps: FieldRenderProps<RadioProps>) => (
              <Radio
                required
                name={fieldProps.input.name}
                onChange={fieldProps.input.onChange}
                value={fieldProps.input.value}
                checkedIcon={
                  <Typography>
                    <span role="img" aria-label="thumbs up emoji">
                      👍
                    </span>
                  </Typography>
                }
                icon={
                  <Typography>
                    <span role="img" aria-label="thumbs up emoji">
                      👍
                    </span>
                  </Typography>
                }
              />
            )}
          />
        }
      />

      <StyledRadioInput
        onClick={handleClick}
        data-heap-skillunknown={props.name}
        name={props.name}
        value="skillUnknown"
        label="J'ai besoin d'apprendre"
        labelPlacement="bottom"
        sx={{
          flexBasis: '50%',
          textAlign: 'center',
        }}
        control={
          <Field
            validate={value => (value ? undefined : 'Required')}
            name={props.name}
            type="radio"
            render={(fieldProps: FieldRenderProps<RadioProps>) => (
              <Radio
                required
                name={fieldProps.input.name}
                onChange={fieldProps.input.onChange}
                value={fieldProps.input.value}
                checkedIcon={
                  <Typography>
                    <span role="img" aria-label="thinking face emoji">
                      🤔
                    </span>
                  </Typography>
                }
                icon={
                  <Typography>
                    <span role="img" aria-label="thinking face emoji">
                      🤔
                    </span>
                  </Typography>
                }
              />
            )}
          />
        }
      />
    </RadioGroup>
  );
};
export default SkillKnowledgeInput;
