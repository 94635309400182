import { Typography, Grid, styled, useMediaQuery } from '@mui/material';
import React from 'react';
import googleRatings from '../images/google_rating.png';
import mfcLogo from '../images/logo_mcf_simple.png';
import qualiopiLogo from '../images/qualiopiLogo.png';
import theme from '../theme';

const StyledTypography = styled(Typography)({
  fontSize: '12px',
  color: theme.palette.primary.darker,
  fontFamily: 'Ubuntu',
});

const ReassuranceElements = () => {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));
  return (
    <Grid container direction="column">
      <Grid container item spacing={2} sx={{ maxHeight: '200px', justifyContent: 'center' }}>
        <Grid item>
          <img src={googleRatings} alt="avis googles" height="64px" />
        </Grid>
        <Grid item>
          <img src={mfcLogo} alt="logo MonCompteFormation" height="68px" />
        </Grid>
        <Grid item>
          <img src={qualiopiLogo} alt="logo Qualiopi" height="64px" />
        </Grid>
        {!isSmallScreen && (
          <Grid item>
            <StyledTypography>
              <b>Atoria</b> par TeamDrill Company
            </StyledTypography>
            <StyledTypography>01 76 34 00 89</StyledTypography>
            <StyledTypography>rue Treilhard, 75008 Paris</StyledTypography>
            <StyledTypography>bonjour@atoria.co</StyledTypography>
          </Grid>
        )}
      </Grid>

      {!isSmallScreen && (
        <Grid item>
          <StyledTypography textAlign="center">
            La certification qualité nous a été délivrée au titre de la catégorie d&apos;action
            suivante : &quot;Actions de Formation&quot;.
          </StyledTypography>
        </Grid>
      )}
    </Grid>
  );
};

export default ReassuranceElements;
