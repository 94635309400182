import {
  LinearProgress,
  Step,
  StepConnector,
  stepConnectorClasses,
  StepLabel,
  stepLabelClasses,
  Stepper,
  styled,
  useMediaQuery,
} from '@mui/material';
import React from 'react';
import { StepWizardChildProps } from 'react-step-wizard';
import theme from '../theme';

const StyledStepLabel = styled(StepLabel)(() => ({
  [`& .${stepLabelClasses.labelContainer}`]: {
    [`& .${stepLabelClasses.active}`]: {
      fontSize: '16px',
      fontWeight: 'bold',
      [theme.breakpoints.down('md')]: {
        fontSize: '10px',
      },
    },
    [`& .${stepLabelClasses.completed}`]: {
      [theme.breakpoints.down('md')]: {
        fontSize: '10px',
      },
    },
    [`& .${stepLabelClasses.disabled}`]: {
      [theme.breakpoints.down('md')]: {
        fontSize: '10px',
      },
    },
  },
}));

const StyledStepConnector = styled(StepConnector)(props => ({
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: props.theme.palette.primary.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: props.theme.palette.primary.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderTopWidth: 2,
    borderRadius: 1,
  },
}));

const steps = ['Détails de la formation', 'Choix de la formule', 'Choix de la date'];

const ProgramStepper: React.FC<Partial<StepWizardChildProps>> = props => {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  if (props.currentStep) {
    const activeStep = props.currentStep - 1;
    if (isSmallScreen) {
      return (
        <LinearProgress variant="determinate" value={props.currentStep * 33} color="primary" />
      );
    }
    return (
      <Stepper
        connector={<StyledStepConnector />}
        activeStep={activeStep}
        sx={{
          maxWidth: '640px',
          marginX: 'auto',
          marginTop: '20px',
          marginBottom: { xs: '0px', md: '20px' },
        }}
      >
        {steps.map(label => (
          <Step key={label}>
            <StyledStepLabel>{label}</StyledStepLabel>
          </Step>
        ))}
      </Stepper>
    );
  }
  return <></>;
};

export default ProgramStepper;
