import React from 'react';
import 'url-search-params-polyfill';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { ABTestWrapper } from './contexts';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_URL,
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ABTestWrapper />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);
