type Topic = 'Excel' | 'Outlook' | 'Excel & Word' | 'La bureautique';

export const validateTopic = (topic: string): Topic => {
  switch (topic) {
    case 'excel':
      return 'Excel';
    case 'outlook':
      return 'Outlook';
    case 'excel-word':
      return 'Excel & Word';
    case 'bureautique':
      return 'La bureautique';
    default:
      throw new Error(`${topic} is not a valid Topic`);
  }
};

export default Topic;
