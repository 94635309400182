import React from 'react';
import {
  FormControlLabel,
  FormControlLabelProps,
  Radio,
  RadioGroup,
  RadioProps,
  styled,
  Typography,
  useRadioGroup,
  Box,
  FormControl,
  FormControlProps,
  Grid,
  useTheme,
} from '@mui/material';
import { Field, FieldRenderProps } from 'react-final-form';
import logoExcel from '../../images/logo_excel.png';
import logoWord from '../../images/logo_word.png';
import logoOutlook from '../../images/logo_outlook.png';
import logoOffice from '../../images/logo_office.png';
import customTheme from '../../theme';

type CardLabelProps = {
  title: string;
};

const CardLabel = ({ title }: CardLabelProps) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        paddingX: 4,
        marginTop: '80px',
        [customTheme.breakpoints.down('md')]: {
          marginTop: '70px',
        },
      }}
    >
      <Typography
        textAlign="center"
        variant="h2"
        color={theme.palette.mode === 'dark' ? 'primary.dark' : 'primary.darker'}
        fontWeight="bold"
      >
        {title}
      </Typography>
    </Box>
  );
};

type CardImageProps = {
  src: string;
  alt: string;
};

const CardImage = ({ src, alt }: CardImageProps) => (
  <Box sx={{ height: '50px', margin: '12px' }}>
    <img src={src} alt={alt} width="114px" height="114px" />
  </Box>
);

interface StyledFormControlLabelProps extends FormControlLabelProps {
  checked: boolean;
}

const StyledFormControlLabel = styled((props: StyledFormControlLabelProps) => (
  <FormControlLabel {...props} />
))(({ theme, checked }) => {
  const baseStyling = {
    backgroundColor: theme.palette.grey[50],
    borderRadius: '4px',
    border: `1px solid ${
      theme.palette.mode === 'dark' ? theme.palette.grey[50] : theme.palette.grey[500]
    }`,
  };

  let additionnalStyling = {};
  if (checked) {
    additionnalStyling = {
      backgroundColor: '#F6FFED',
      border: `4px solid ${theme.palette.success.main}`,
    };
  }
  return { ...baseStyling, ...additionnalStyling };
});

type StyledRadioInputProps = {
  value: string;
  labelElement: JSX.Element;
  iconElement: JSX.Element;
  goToNextStep?: (step: string) => void;
};

const StyledRadioInput = (props: StyledRadioInputProps) => {
  const handleClick = () => {
    setTimeout(() => props.goToNextStep && props.goToNextStep('selfAssessedLevel'), 100);
  };
  const radioGroup = useRadioGroup();

  let checked = false;

  if (radioGroup) {
    checked = radioGroup.value === props.value;
  }

  return (
    <Grid item xs={1} sm={1}>
      <StyledFormControlLabel
        onClick={handleClick}
        checked={checked}
        data-heap-topic={props.value}
        name="topic"
        value={props.value}
        label={props.labelElement}
        labelPlacement="bottom"
        sx={{
          flexBasis: '47%',
          height: '240px',
          width: '100%',
          paddingTop: '15px',
          [customTheme.breakpoints.down('md')]: {
            paddingTop: '10px',
            margin: '0px',
            height: '200px',
          },
        }}
        control={
          <Field
            validate={value => (value ? undefined : 'Required')}
            name="topic"
            type="radio"
            render={(fieldProps: FieldRenderProps<RadioProps>) => (
              <Radio
                required
                inputProps={{ required: true }}
                name={fieldProps.input.name}
                onChange={fieldProps.input.onChange}
                value={fieldProps.input.value}
                checkedIcon={props.iconElement}
                icon={props.iconElement}
              />
            )}
          />
        }
      />
    </Grid>
  );
};

export interface TopicButtonsProps {
  formControlProps?: Partial<FormControlProps>;
  goToNextStep?: (step: string) => void;
}

const TopicButtons = (props: TopicButtonsProps) => (
  <FormControl required {...props.formControlProps}>
    <RadioGroup>
      <Grid
        container
        justifyContent="center"
        direction="row"
        spacing={2}
        columns={{ xs: 1, sm: 2 }}
      >
        {/* Excel */}
        <StyledRadioInput
          goToNextStep={props.goToNextStep}
          value="Excel"
          labelElement={<CardLabel title="Excel" />}
          iconElement={<CardImage src={logoExcel} alt="Excel logo" />}
        />

        {/* Excel & word */}
        <StyledRadioInput
          goToNextStep={props.goToNextStep}
          value="Excel & Word"
          labelElement={<CardLabel title="Excel et Word" />}
          iconElement={
            <>
              <CardImage src={logoExcel} alt="Excel logo" />
              <CardImage src={logoWord} alt="Word logo" />
            </>
          }
        />

        {/* Office */}
        <StyledRadioInput
          goToNextStep={props.goToNextStep}
          value="La bureautique"
          labelElement={<CardLabel title="Suite Office" />}
          iconElement={<CardImage src={logoOffice} alt="office logo" />}
        />

        {/* Outlook */}
        <StyledRadioInput
          goToNextStep={props.goToNextStep}
          value="Outlook"
          labelElement={<CardLabel title="Outlook" />}
          iconElement={<CardImage src={logoOutlook} alt="Outlook logo" />}
        />
      </Grid>
    </RadioGroup>
  </FormControl>
);
export default TopicButtons;
