import { Avatar, Box, easing, Grow } from '@mui/material';
import { SxProps } from '@mui/system';
import React from 'react';
import ambreAvatar from '../images/Ambre_zoom.png';
import theme from '../theme';

type FormMessageProps = {
  sx?: SxProps;
};

const FormMessage: React.FC<FormMessageProps> = props => (
  <Grow in mountOnEnter unmountOnExit timeout={1000} easing={{ exit: easing.easeOut }}>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignSelf: 'flex-start',
        width: '100%',
        marginTop: 3,
        [theme.breakpoints.down('md')]: {
          marginY: '16px',
        },
        ...props.sx,
      }}
    >
      <Avatar
        alt="Ambre Puech, CEO d'atoria"
        src={ambreAvatar}
        sx={{
          width: 64,
          height: 64,
          [theme.breakpoints.down('md')]: {
            width: 46,
            height: 46,
          },
        }}
      />
      <Box
        sx={{
          backgroundColor: 'primary.main',
          paddingLeft: '20px',
          paddingRight: '40px',
          py: '20px',
          borderRadius: '4px',
          marginLeft: '13px',
          marginBottom: '10px',
          flexGrow: 2,
          color: 'white',
          [theme.breakpoints.down('md')]: {
            fontSize: '14px',
            paddingRight: '20px',
            paddingY: '14px',
            marginBottom: '0px',
            marginLeft: '10px',
          },
        }}
      >
        {props.children}{' '}
      </Box>
    </Box>
  </Grow>
);

export default FormMessage;
