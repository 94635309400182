import { Container, Slide, Typography, Box } from '@mui/material';
import React from 'react';
import { useForm } from 'react-final-form';
import { StepWizardChildProps } from 'react-step-wizard';
import { Loader, ReassuranceElementsFormPage } from '..';
import { FormAnswer } from '../../entities';
import FormMessage from '../FormMessage';
import { NextPrevButtonGroup, RadiosWithAnalytics } from '../inputs';
import TopicSentences from './types/TopicSentences';

const topicQuestionSentences: TopicSentences = {
  Excel: "Comment utilisez-vous Excel aujourd'hui ?",
  Outlook: "Comment utilisez-vous Outlook aujourd'hui ?",
  'Excel & Word': "Comment utilisez-vous Word et Excel aujourd'hui ?",
  'La bureautique': "Comment utilisez-vous ces logiciels aujourd'hui ?",
};

const topicChoice1Sentences: TopicSentences = {
  Excel: (
    <span>
      Je ne l&apos;ai <b>jamais utilisé</b> ou presque
    </span>
  ),
  Outlook: (
    <span>
      Je ne l&apos;ai <b>jamais utilisé</b> ou presque
    </span>
  ),
  'Excel & Word': (
    <span>
      Je ne les ai <b>jamais utilisés</b> ou presque
    </span>
  ),
  'La bureautique': (
    <span>
      Je ne les ai <b>jamais utilisés</b> ou presque
    </span>
  ),
};

const topicChoice2Sentences: TopicSentences = {
  Excel: (
    <span>
      Je l&apos;utilise <b>de temps en temps</b>
    </span>
  ),
  Outlook: (
    <span>
      Je l&apos;utilise <b>de temps en temps</b>
    </span>
  ),
  'Excel & Word': (
    <span>
      Je les utilise <b>de temps en temps</b>
    </span>
  ),
  'La bureautique': (
    <span>
      Je les utilise <b>de temps en temps</b>
    </span>
  ),
};

const topicChoice3Sentences: TopicSentences = {
  Excel: (
    <span>
      Je l&apos;utilise <b>quotidiennement</b>
    </span>
  ),
  Outlook: (
    <span>
      Je l&apos;utilise <b>quotidiennement</b>
    </span>
  ),
  'Excel & Word': (
    <span>
      Je les utilise <b>quotidiennement</b>
    </span>
  ),
  'La bureautique': (
    <span>
      Je les utilise <b>quotidiennement</b>
    </span>
  ),
};

const SelfAssessedLevel: React.FC<Partial<StepWizardChildProps>> = props => {
  const form = useForm<FormAnswer>('useFormState');
  let formState = form.getState();
  const { topic } = formState.values;
  if (props.goToNamedStep && !topic) {
    props.goToNamedStep('topicChoice');
    return <Loader />;
  }

  const goToNextStep = () => {
    formState = form.getState();
    if (props.goToNamedStep && !formState.hasValidationErrors) {
      if (formState.values.selfAssessedLevel === 'beginner') {
        props.goToNamedStep('computerUsage');
      } else if (
        formState.values.selfAssessedLevel === 'novice' ||
        formState.values.selfAssessedLevel === 'intermediate'
      ) {
        props.goToNamedStep('skillKnowledge');
      }
    }
  };

  return (
    <Container
      maxWidth="lg"
      sx={{
        height: '65vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingTop: 2,
      }}
    >
      <Slide timeout={1200} direction="left" in mountOnEnter unmountOnExit>
        <Container
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
          maxWidth="md"
        >
          <FormMessage>
            <Typography marginBottom="20px" color="grey.100">
              Je vais vous aider à progresser sur {topic}. Afin de vous proposer une formation sur
              mesure, je dois vous poser quelques questions.
            </Typography>
            <Typography color="grey.50" variant="h1" fontSize="24px">
              {topicQuestionSentences[topic]}
            </Typography>
          </FormMessage>
          <Box
            sx={{
              flexGrow: 1,
            }}
          >
            <RadiosWithAnalytics
              goToNextStep={goToNextStep}
              required
              formControlLabelProps={{
                sx: {
                  backgroundColor: 'white',
                  padding: '10px',
                  borderRadius: '4px',
                  margin: '5px',
                },
              }}
              name="selfAssessedLevel"
              data={[
                {
                  label: topicChoice1Sentences[topic],
                  value: 'beginner',
                },
                {
                  label: topicChoice2Sentences[topic],
                  value: 'novice',
                },
                {
                  label: topicChoice3Sentences[topic],
                  value: 'intermediate',
                },
              ]}
            />
          </Box>

          <Box paddingTop={5}>
            <ReassuranceElementsFormPage />
          </Box>
        </Container>
      </Slide>
      <NextPrevButtonGroup
        nextStep={goToNextStep}
        disableNextButton={formState.hasValidationErrors}
      />
    </Container>
  );
};

export default SelfAssessedLevel;
