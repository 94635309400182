import {
  FormAnswer,
  Formula,
  Skill,
  Template,
  Topic,
  FormulaCustomId,
  TemplateIds,
} from '../entities';
import getFormulasValues from '../entities/rules/FormulasValues';

type AttributionData = {
  templates: Template[];
  skills: Skill[];
  formAnswers: FormAnswer;
};

export default function getProgram({ templates, skills, formAnswers }: AttributionData): Formula[] {
  const { topic } = formAnswers;
  const chosenTemplateId = getTemplateId(formAnswers, topic);
  const chosenTemplate = templates.find((t: Template) => chosenTemplateId === t.id);
  if (!chosenTemplate) {
    throw new Error('Template id not in template list');
  }

  return createProgram(chosenTemplate, skills, topic);
}

export function getTemplateId(formAnswers: FormAnswer, topic: Topic): string {
  try {
    const { selfAssessedLevel } = formAnswers;
    const numberOfKnownSkills = getNumberOfKnownSkills(formAnswers);

    switch (topic) {
      case 'Excel':
        switch (selfAssessedLevel) {
          case 'beginner':
            return TemplateIds.excel_beginner;
          case 'novice':
            if (numberOfKnownSkills < 4) {
              return TemplateIds.excel_novice;
            }
            return TemplateIds.excel_intermediate;
          case 'intermediate':
            if (numberOfKnownSkills <= 1) {
              return TemplateIds.excel_novice;
            }
            if (numberOfKnownSkills <= 3) {
              return TemplateIds.excel_intermediate;
            }
            return TemplateIds.excel_advanced;
          default:
            throw new Error(
              `No template was found for Excel ${selfAssessedLevel}(with ${numberOfKnownSkills} knownSkills)`,
            );
        }
      case 'Excel & Word':
        switch (selfAssessedLevel) {
          case 'beginner':
          case 'novice':
            return TemplateIds.excelWord_beginner;
          case 'intermediate':
            return TemplateIds.excelWord_intermediate;
          default:
            throw new Error(`No template was found for Excel & Word ${selfAssessedLevel}`);
        }
      case 'Outlook':
        switch (selfAssessedLevel) {
          case 'beginner':
          case 'novice':
          case 'intermediate':
            return TemplateIds.outlook_beginner;
          default:
            throw new Error(`No template was found Outlook ${selfAssessedLevel}`);
        }
      case 'La bureautique':
        switch (selfAssessedLevel) {
          case 'beginner':
          case 'novice':
          case 'intermediate':
            return TemplateIds.bureautique_beginner;
          default:
            throw new Error(`No template was found for La bureautique ${selfAssessedLevel}`);
        }
      default:
        throw new Error(`No template was found for ${topic}`);
    }
  } catch (e: any) {
    throw Error(e);
  }
}

export function getNumberOfKnownSkills(formAnswers: FormAnswer): number {
  const entries = Object.entries(formAnswers);
  const knownSkills = entries.filter(e => e[1] === 'skillKnown');
  return knownSkills.length;
}

function createProgram(template: Template, skills: Skill[], topic: Topic): Formula[] {
  const formulaA = createFormula(
    skills,
    template.skillsOfFormulaA,
    getFormulasValues(FormulaCustomId.a, topic),
  );
  const formulaB = createFormula(
    skills,
    template.skillsOfFormulaB,
    getFormulasValues(FormulaCustomId.b, topic),
  );
  const formulaC = createFormula(
    skills,
    template.skillsOfFormulaC,
    getFormulasValues(FormulaCustomId.c, topic),
  );
  const formulaD = createFormula(
    skills,
    template.skillsOfFormulaD,
    getFormulasValues(FormulaCustomId.d, topic),
  );

  return [formulaA, formulaB, formulaC, formulaD];
}

function createFormula(allSkills: Skill[], skillsToAddToFormula: string[], base: Formula): Formula {
  const skillInFormula: Skill[] = [];

  skillsToAddToFormula.forEach((skillIdToAdd: string) => {
    const fullSkill = allSkills.find((skill: Skill) => skill.id === skillIdToAdd);
    if (fullSkill) {
      skillInFormula.push(fullSkill);
    }
  });

  const formula = base;
  formula.skills = skillInFormula;
  return formula;
}
