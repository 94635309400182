import { AppBar, Toolbar, Button, Grid, useMediaQuery, Box } from '@mui/material';
import React from 'react';
import PermPhoneMsgIcon from '@mui/icons-material/PermPhoneMsg';
import logoFull from '../images/Logo-Name-Trans-BG-128.png';
import logoSmall from '../images/Logo-Trans-BG-SVG.svg';
import theme from '../theme';

type ProgramHeaderProps = {
  elevation?: number;
  position?: 'fixed' | 'absolute' | 'sticky' | 'static' | 'relative';
  activeStep?: number;
  goToNextStep: () => void;
};

const ProgramHeader = ({
  elevation = 0,
  position = 'sticky',
  activeStep,
  goToNextStep,
}: ProgramHeaderProps) => {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const smallLogo = (
    <img src={logoSmall} alt="Atoria logo transparent background" height="46px" width="46px" />
  );
  const fullLogo = <img src={logoFull} alt="Atoria logo transparent background" />;
  const logo = isSmallScreen ? smallLogo : fullLogo;
  return (
    <AppBar position={position} elevation={elevation}>
      <Toolbar
        sx={{
          backgroundColor: 'white',
          height: '70px',
          verticalAlign: 'middle',
          [theme.breakpoints.down('sm')]: {
            height: '48px',
          },
        }}
      >
        <Grid
          container
          wrap="nowrap"
          alignItems="center"
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, md: 12, lg: 12 }}
          sx={{ margin: 0 }}
        >
          <Grid item xs={2} md={2} lg={2}>
            {logo}
          </Grid>
          {/* navBar */}
          <Grid
            item
            xs={0}
            md={6}
            lg={6}
            sx={{
              [theme.breakpoints.down('md')]: {
                display: 'none',
              },
            }}
          >
            <Box sx={{ width: '100%', height: '100%' }} />
          </Grid>
          {/* buttons */}
          <Grid
            item
            container
            wrap="nowrap"
            spacing={{ xs: 1 }}
            columns={{ xs: 2, md: 2, lg: 12 }}
            direction="row"
            justifyContent="flex-end"
          >
            <Grid
              item
              sx={{
                [theme.breakpoints.down('sm')]: {
                  display: 'none',
                },
              }}
            >
              <Button
                sx={{
                  float: 'right',
                  [theme.breakpoints.down('sm')]: {
                    display: 'none',
                  },
                }}
                variant="outlined"
              >
                <PermPhoneMsgIcon sx={{ paddingRight: '8px' }} />
                01 76 34 00 89
              </Button>
            </Grid>
            {activeStep === 0 && (
              <Grid item>
                <Button
                  color="success"
                  variant="contained"
                  onClick={() => goToNextStep()}
                  sx={{
                    fontWeight: '600',
                    color: 'grey.50',
                    textTransform: 'none',
                    minWidth: '156px',
                  }}
                >
                  Parler à un conseiller
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default React.memo(ProgramHeader);
