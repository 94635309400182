import { AppBar, Toolbar, Button, Grid, useMediaQuery } from '@mui/material';
import React from 'react';
import PermPhoneMsgIcon from '@mui/icons-material/PermPhoneMsg';
import logoFull from '../images/Logo-Name-Trans-BG-128.png';
import logoSmall from '../images/Logo-Trans-BG-SVG.svg';
import theme from '../theme';

type HeaderProps = {
  elevation?: number;
  position?: 'fixed' | 'absolute' | 'sticky' | 'static' | 'relative';
};

const Header = ({ elevation = 0, position = 'sticky' }: HeaderProps) => {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const smallLogo = (
    <img src={logoSmall} alt="Atoria logo transparent background" height="46px" width="46px" />
  );
  const fullLogo = <img src={logoFull} alt="Atoria logo transparent background" />;
  const logo = isSmallScreen ? smallLogo : fullLogo;
  return (
    <AppBar position={position} elevation={elevation}>
      <Toolbar
        sx={{
          backgroundColor: 'white',
          height: '70px',
          verticalAlign: 'middle',
          [theme.breakpoints.down('sm')]: {
            height: '48px',
          },
        }}
      >
        <Grid
          container
          wrap="nowrap"
          alignItems="center"
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item xs={2} md={10}>
            {logo}
          </Grid>
          <Grid item xs={2} md={2}>
            <Button
              sx={{
                minWidth: '156px',
                [theme.breakpoints.down('sm')]: {
                  height: '32px',
                },
              }}
              variant="outlined"
            >
              <PermPhoneMsgIcon sx={{ paddingRight: '8px' }} />
              01 76 34 00 89
            </Button>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default React.memo(Header);
