import { Box, Button, Container, Grid, Link, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { StepWizardChildProps } from 'react-step-wizard';
import { FormMessage, ReassuranceElementsProgramPage } from '..';
import { Formula } from '../../entities';
import logoMCF from '../../images/logo_mcf_simple.png';
import theme from '../../theme';
import { sendMCFLinkClickedEmail } from '../../http/bubble';

type CardWithLinkProps = {
  title: string;
  content: string;
  linkRef: string;
  linkText: string;
};

const CardWithLink = ({ title, content, linkRef, linkText }: CardWithLinkProps) => (
  <Grid item xs={1} md={1} sx={{ padding: '24px' }}>
    <Typography fontWeight="bold">{title}</Typography>
    <Typography variant="body2" sx={{ marginY: '10px' }}>
      {content}
    </Typography>
    <Link data-heap-link={title} href={linkRef} underline="none">
      <Typography fontWeight="bold" color="primary.main">
        <ArrowForwardIcon
          sx={{
            marginRight: '4px',
            marginBottom: '4px',
            verticalAlign: 'middle',
            color: 'primary.main',
          }}
        />
        {linkText}
      </Typography>
    </Link>
  </Grid>
);

type ProgramConfirmationPageProps = Partial<StepWizardChildProps> & {
  selectedFormula?: Formula;
  trainingId: string;
};

const ProgramConfirmationPage: React.FC<ProgramConfirmationPageProps> = props => {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Grid
      container
      direction="column"
      flexWrap="nowrap"
      sx={{ paddingTop: '48px', alignItems: 'center', height: '80vh' }}
    >
      <Grid item>
        <CheckCircleIcon color="success" sx={{ fontSize: '52px' }} />
      </Grid>
      <Grid item>
        <Typography variant="h1Program" sx={{ marginTop: 0, marginBottom: 0 }}>
          Super, votre créneau pour discuter de votre formation est bien reservé !
        </Typography>
      </Grid>
      <Grid item>
        <Container maxWidth="md">
          <FormMessage>Il ne vous reste plus qu’à régler le montant de votre formation</FormMessage>
        </Container>
      </Grid>
      <Grid item>
        {!isSmallScreen && (
          <img
            src={logoMCF}
            alt="logo officiel MonCompteFormation"
            style={{
              height: '120px',
            }}
          />
        )}
        <Button
          data-heap-button="MCF Link"
          type="button"
          color="success"
          sx={{
            marginTop: 4,
            marginLeft: 4,
            fontWeight: '600',
            float: 'right',
            color: 'grey.50',
            height: theme.spacing(7),
          }}
          variant="contained"
          onClick={() => {
            sendMCFLinkClickedEmail(props.trainingId);
            window.open(props.selectedFormula?.mfcLink);
          }}
        >
          M&apos;inscrire avec mon CPF <ArrowForwardIcon sx={{ paddingLeft: 1 }} />
        </Button>
      </Grid>
      <Grid item>
        <Container maxWidth="lg" sx={{ marginTop: '48px' }}>
          <Grid item container columns={{ xs: 1, md: 3 }} sx={{ alignContent: 'space-between' }}>
            <CardWithLink
              title="Préparez vous à la formation"
              content="Un petit tutoriel pour installer Word ou Excel sur votre ordinateur. "
              linkRef="https://support.microsoft.com/fr-fr/office/t%C3%A9l%C3%A9chargez-et-installez-ou-r%C3%A9installez-microsoft-365-ou-office-2021-sur-un-pc-ou-un-mac-4414eaaf-0478-48be-9c42-23adc4716658"
              linkText="Installer Office 365"
            />
            <CardWithLink
              title="Découvez notre philosophie et notre équipe"
              content="Nous proposons des formations avec un format spécifique, découvrez pourquoi nous avons fait ce choix"
              linkRef="https://www.atoria.co/a-propos"
              linkText="Lire le manifeste"
            />
            <CardWithLink
              title="Comment choisir entre les différentes proposition de formation que vous avez reçues ?"
              content=" Voici notre guide pour comprendre quelle est la formation idéale pour vous"
              linkRef="https://www.atoria.co/choisir-sa-formation-excel"
              linkText="Lire le guide"
            />
          </Grid>
        </Container>
      </Grid>
      <Grid item>
        <Box sx={{ marginTop: '60px' }}>
          <ReassuranceElementsProgramPage />
        </Box>
      </Grid>
    </Grid>
  );
};

export default ProgramConfirmationPage;
