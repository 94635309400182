import { CircularProgress, Container } from '@mui/material';
import React from 'react';

const Loader = () => (
  <Container
    maxWidth="lg"
    sx={{
      mt: '100px',
      height: '65vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}
  >
    <CircularProgress />
  </Container>
);

export default Loader;
