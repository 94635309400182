import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { FormPage, NotFound, ProgramPage } from './pages/index';

const App = () => {
  useEffect(() => {
    // using @ts-ignore instructions here as Crips adds methods on run time to
    // the windows object, and there are no types coming from the loaded SDK
    // @ts-ignore
    window.$crisp = [];
    // @ts-ignore
    window.CRISP_WEBSITE_ID = process.env.REACT_APP_CRISP_ID;
    const mount = () => {
      const scriptTag = document.createElement('script');
      scriptTag.src = 'https://client.crisp.chat/l.js';
      scriptTag.async = true;
      document.getElementsByTagName('head')[0].appendChild(scriptTag);
    };
    mount();
    // @ts-ignore
    $crisp.push(['config', 'hide:on:mobile', [true]]);
  }, []);
  return (
    <Switch>
      <Route path="/" exact>
        <Redirect to="/questionnaire" />
      </Route>
      <Route path="/questionnaire">
        <FormPage />
      </Route>
      <Route path="/programme" exact>
        <Redirect to="/" />
      </Route>
      <Route path="/programme/:trainingId">
        <ProgramPage />
      </Route>
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  );
};

export default App;
