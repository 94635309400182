import React from 'react';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import theme from '../theme';

type TabWithChipProps = TabWithChipForSmallScreenProps & {
  price: number;
  hours: number;
};

type TabWithChipForSmallScreenProps = {
  title: string;
};

const TabWithChipSmallScreen = ({ title }: TabWithChipForSmallScreenProps) => (
  <Grid
    container
    direction="column"
    columns={{ sx: 1, md: 1 }}
    spacing={1}
    sx={{ padding: '8px', alignItems: 'baseline' }}
  >
    <Grid item xs={1}>
      <Typography fontSize="12px" fontWeight="bold" color="primary.darker" align="left">
        Formule{' '}
      </Typography>
      <Typography fontSize="12px" fontWeight="bold" color="primary.darker" align="left">
        {title}
      </Typography>
    </Grid>
  </Grid>
);

const TabWithChipLargeScreen = ({ price, hours, title }: TabWithChipProps) => (
  <Grid
    container
    direction="row"
    columns={{ sx: 1, md: 1 }}
    spacing={1}
    sx={{ padding: '8px', justifyContent: 'flex-start' }}
  >
    <Grid item xs={1} md={1}>
      <Typography fontWeight="bold" color="primary.darker" align="left">
        Formule {title}
      </Typography>
      <Typography color="primary.darker" align="left">
        {price}€ - {hours}h de formation
      </Typography>
    </Grid>
  </Grid>
);

const TabWithChip = ({ price, hours, title }: TabWithChipProps) => {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  if (isSmallScreen) {
    return <TabWithChipSmallScreen title={title} />;
  }
  return <TabWithChipLargeScreen price={price} hours={hours} title={title} />;
};

export default TabWithChip;
