import React, { JSXElementConstructor, ReactElement, ReactNode } from 'react';

import {
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormControlProps,
  FormHelperTextProps,
  FormLabel,
  FormLabelProps,
  Radio as MuiRadio,
  RadioProps as MuiRadioProps,
  RadioGroup,
  RadioGroupProps,
} from '@mui/material';

import { Field, FieldProps } from 'react-final-form';
import theme from '../../theme';

export interface RadioData {
  label: string | number | ReactElement<any, string | JSXElementConstructor<any>>;
  value: unknown;
  disabled?: boolean;
}

export interface RadiosWithAnalyticsProps extends Partial<Omit<MuiRadioProps, 'onChange'>> {
  name: string;
  data: RadioData[];
  label?: ReactNode;
  required?: boolean;
  helperText?: string;
  formLabelProps?: Partial<FormLabelProps>;
  formControlLabelProps?: Partial<FormControlLabelProps>;
  fieldProps?: Partial<FieldProps<any, any>>;
  formControlProps?: Partial<FormControlProps>;
  radioGroupProps?: Partial<RadioGroupProps>;
  formHelperTextProps?: Partial<FormHelperTextProps>;
  goToNextStep: () => void;
}

export function RadiosWithAnalytics(props: RadiosWithAnalyticsProps) {
  const {
    name,
    data,
    label,
    required,
    helperText,
    formLabelProps,
    formControlLabelProps,
    fieldProps,
    formControlProps,
    radioGroupProps,
    formHelperTextProps,
    goToNextStep,
    ...restRadios
  } = props;

  const handleClick = () => {
    setTimeout(() => goToNextStep(), 100);
  };

  return (
    <FormControl
      required={required}
      {...formControlProps}
      sx={{
        width: '460px',
        [theme.breakpoints.down('sm')]: {
          width: '90vw',
          [`& .MuiRadio-root`]: {
            paddingX: '0px',
            width: '50px',
          },
        },
        ...formControlProps?.sx,
      }}
    >
      {!!label && <FormLabel {...formLabelProps}>{label}</FormLabel>}
      <RadioGroup {...radioGroupProps}>
        {data.map((item: RadioData) => (
          <FormControlLabel
            onClick={handleClick}
            data-heap-radio={item.value}
            key={`${item.value}`}
            name={name}
            label={item.label}
            value={item.value}
            disabled={item.disabled}
            control={
              <Field
                validate={value => (value ? undefined : 'Required')}
                name={name}
                type="radio"
                render={({ input: { fieldName, value, onChange, checked, ...restInput } }) => (
                  <MuiRadio
                    sx={{
                      marginX: '10px',
                      maxWidth: '70vw',
                    }}
                    name={fieldName}
                    value={value}
                    onChange={onChange}
                    checked={checked}
                    disabled={item.disabled}
                    required={required}
                    inputProps={{ required, ...restInput }}
                    {...restRadios}
                  />
                )}
                {...fieldProps}
              />
            }
            {...formControlLabelProps}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}
