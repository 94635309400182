import { Container, Slide, Typography } from '@mui/material';
import React from 'react';
import { StepWizardChildProps } from 'react-step-wizard';
import { useForm } from 'react-final-form';
import { FormMessage } from '..';
import { NextPrevButtonGroup, RadiosWithAnalytics } from '../inputs';
import { FormAnswer } from '../../entities';
import Loader from '../Loader';

const ComputerOwnership: React.FC<Partial<StepWizardChildProps>> = props => {
  const form = useForm<FormAnswer>('useFormState');
  let formState = form.getState();
  const { selfAssessedLevel } = formState.values;
  if (props.goToNamedStep && !selfAssessedLevel) {
    props.goToNamedStep('topicChoice');
    return <Loader />;
  }

  const goToNextStep = () => {
    formState = form.getState();
    if (props.goToNamedStep && !formState.hasValidationErrors) {
      props.goToNamedStep('generation');
    }
  };

  return (
    <Container
      maxWidth="lg"
      sx={{
        height: '65vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingTop: 2,
      }}
    >
      <Slide timeout={1200} direction="left" in mountOnEnter unmountOnExit>
        <Container
          sx={{
            height: '40vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
          maxWidth="md"
        >
          <FormMessage>
            <Typography marginBottom="20px" color="grey.100">
              Vos sessions individuelles se feront à distance en visioconférence avec votre
              formateur.
            </Typography>
            <Typography color="grey.50" variant="h1" fontSize={{ sx: '22px', sm: '24px' }}>
              Quel d&apos;ordinateur avez-vous pour suivre votre formation en ligne ?
            </Typography>
          </FormMessage>
          <RadiosWithAnalytics
            goToNextStep={goToNextStep}
            required
            formControlLabelProps={{
              sx: {
                backgroundColor: 'white',
                padding: '10px',
                borderRadius: '4px',
                margin: '5px',
              },
            }}
            name="computerOwnership"
            data={[
              {
                label: "J'ai un PC",
                value: 'pc',
              },
              {
                label: "J'ai un Mac",
                value: 'mac',
              },
              {
                label: "Je n'ai pas d'ordinateur",
                value: 'none',
              },
            ]}
          />
        </Container>
      </Slide>
      <NextPrevButtonGroup
        nextStep={goToNextStep}
        disableNextButton={formState.hasValidationErrors}
      />
    </Container>
  );
};

export default ComputerOwnership;
