import { Button, AppBar, Toolbar, Grid, Typography } from '@mui/material';
import React from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import theme from '../theme';
import { Formula as FormulaType } from '../entities';

type ProgramFooterProps = {
  previousStep?: () => void;
  nextStep?: () => void;
  nextButtonType?: 'button' | 'submit' | 'reset';
  nextButtonText?: string;
  disableNextButton?: boolean;
  selectedFormula?: FormulaType;
};

const ProgramFooter = ({
  previousStep,
  nextStep,
  nextButtonType = 'submit',
  disableNextButton = false,
  nextButtonText = 'Suivant',
  selectedFormula,
}: ProgramFooterProps) => (
  <AppBar position="fixed" color="transparent" sx={{ top: 'auto', bottom: 0 }} elevation={4}>
    <Toolbar
      sx={{
        margin: 'auto',
        backgroundColor: 'white',
        height: '96px',
        width: '100%',
        alignItems: 'flex-start',
        [theme.breakpoints.down('md')]: {
          alignItems: 'center',
          verticalAlign: 'middle',
          backgroundColor: 'white',
          width: '100%',
          height: '70px',
        },
      }}
    >
      <Grid
        direction="row-reverse"
        container
        wrap="nowrap"
        spacing={{ xs: 0, md: 0 }}
        columns={{ xs: 2, sm: 12, md: 12, lg: 12 }}
        alignItems="center"
        alignContent="space-between"
        height="100%"
        marginX="15vw"
        sx={{
          [theme.breakpoints.down('md')]: {
            marginX: '10px',
          },
        }}
      >
        <Grid item xs={1} md={4} lg={2}>
          <Button
            data-heap-nav-button="next"
            type={nextButtonType || 'button'}
            disabled={disableNextButton}
            disableElevation
            color="success"
            sx={{
              fontWeight: '600',
              float: 'right',
              color: 'grey.50',
              height: theme.spacing(7),
              [`&.Mui-disabled`]: {
                opacity: 0.4,
                backgroundColor: 'success.dark',
                color: '#F6FFED',
              },
            }}
            variant="contained"
            onClick={nextStep}
          >
            {nextButtonText} <ArrowForwardIcon sx={{ paddingLeft: 1 }} />
          </Button>
        </Grid>
        {selectedFormula && (
          <Grid
            item
            xs={2}
            sm={3}
            md={6}
            lg={4}
            flexGrow={4}
            sx={{
              padding: '8px',
              height: '100%',
              backgroundColor: theme.palette.primary.lighter,
              borderLeft: `1px solid ${theme.palette.grey[300]}`,
              borderRight: `1px solid ${theme.palette.grey[300]}`,
              float: 'right',
              marginRight: '16px',
            }}
          >
            <Typography fontSize={{ xs: '8px', md: '14px' }}>Votre selection</Typography>
            <Typography fontWeight="bold" color="primary.darker" align="left">
              Formule {selectedFormula.title}
            </Typography>
            <Typography color="primary.darker" align="left">
              {selectedFormula.price}€ - {selectedFormula.hours}h de formation
            </Typography>
          </Grid>
        )}
        {previousStep && (
          <Grid item xs={1} md={9}>
            <Button
              data-heap-nav-button="prev"
              disableElevation
              sx={{
                height: theme.spacing(6),
                color: 'primary',
                borderColor: 'primary',
                [theme.breakpoints.down('md')]: {
                  color: 'primary.main',
                  borderColor: 'primary.main',
                },
              }}
              variant="text"
              onClick={previousStep}
            >
              <ArrowBackIcon sx={{ paddingRight: 1 }} /> Retour
            </Button>
          </Grid>
        )}
      </Grid>
    </Toolbar>
  </AppBar>
);

export default ProgramFooter;
