import { Container, Slide, Typography } from '@mui/material';
import React from 'react';
import { useForm } from 'react-final-form';
import { StepWizardChildProps } from 'react-step-wizard';
import { FormAnswer } from '../../entities';
import { TopicButtons } from '../inputs';

const TopicChoice: React.FC<Partial<StepWizardChildProps>> = props => {
  const form = useForm<FormAnswer>('useFormState');
  const formState = form.getState();
  const { topic } = formState.values;
  if (topic && props.goToNamedStep) {
    props.goToNamedStep('selfAssessedLevel');
  }

  return (
    <Container
      maxWidth="lg"
      sx={{
        height: '65vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingTop: 2,
      }}
    >
      <Slide timeout={1200} direction="left" in mountOnEnter unmountOnExit>
        <Container
          sx={{
            height: '40vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
          maxWidth="md"
        >
          <Typography
            variant="h1"
            textAlign="center"
            fontSize={{ sx: '22px', sm: '24px' }}
            marginBottom="20px"
          >
            <span role="img" aria-label="hand pointing right emoji">
              👉
            </span>{' '}
            Sur quel logiciel souhaitez-vous progresser ?
          </Typography>
          <TopicButtons goToNextStep={props.goToNamedStep} />
        </Container>
      </Slide>
    </Container>
  );
};

export default TopicChoice;
