import { CssBaseline, ThemeProvider } from '@mui/material';
import React, { useState } from 'react';
import App from '../App';
import { ABTestEnum } from '../entities';
import { fullTheme } from '../theme';

export const ABTestContext = React.createContext(ABTestEnum.undefined);

const ABTestWrapper = () => {
  const [abTestOption] = useState<ABTestEnum>(ABTestEnum.undefined);

  return (
    <ABTestContext.Provider value={abTestOption}>
      <ThemeProvider theme={fullTheme.dark}>
        <CssBaseline>
          <App />
        </CssBaseline>
      </ThemeProvider>
    </ABTestContext.Provider>
  );
};

export default ABTestWrapper;
