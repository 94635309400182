import { Typography, Grid, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import googleRatingsDark from '../images/google_rating_64px_white_text.png';
import googleRatingsLight from '../images/google_rating_64px_black_text.png';
import mfcLogo from '../images/logo_mcf_simple.png';
import qualiopiLogo from '../images/qualiopiLogo.png';

const ReassuranceElements = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));
  return (
    <Grid container direction="column">
      <Grid container item spacing={2} sx={{ maxHeight: '200px', justifyContent: 'center' }}>
        <Grid item>
          <img
            src={theme.palette.mode === 'dark' ? googleRatingsDark : googleRatingsLight}
            alt="avis googles"
            height="64px"
          />
        </Grid>
        <Grid item>
          <img src={mfcLogo} alt="logo MonCompteFormation" height="68px" />
        </Grid>
        <Grid item>
          <img src={qualiopiLogo} alt="logo Qualiopi" height="64px" />
        </Grid>
        {!isSmallScreen && (
          <Grid item>
            <Typography variant="body3">
              <b>Atoria</b> par TeamDrill Company
            </Typography>
            <Typography variant="body3">01 76 34 00 89</Typography>
            <Typography variant="body3">rue Treilhard, 75008 Paris</Typography>
            <Typography variant="body3">bonjour@atoria.co</Typography>
          </Grid>
        )}
      </Grid>

      {!isSmallScreen && (
        <Grid item>
          <Typography variant="body3" textAlign="center">
            La certification qualité nous a été délivrée au titre de la catégorie d&apos;action
            suivante : &quot;Actions de Formation&quot;.
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default ReassuranceElements;
