import React from 'react';
import { Container, Grid, Slide, Typography } from '@mui/material';
import { StepWizardChildProps } from 'react-step-wizard';
import { useForm } from 'react-final-form';
import { useTheme } from '@mui/system';
import { NextPrevButtonGroup, SkillKnowledgeInput } from '../inputs';
import { Topic, FormAnswer } from '../../entities';
import Loader from '../Loader';
import FormMessage from '../FormMessage';

type QuestionType = {
  text: string;
  subText?: string;
  id: string;
};

type QuestionHashTableType = {
  [topic in Topic]: QuestionType[];
};

const QuestionsPerTopics: QuestionHashTableType = {
  Excel: [
    {
      text: 'Lire et naviguer dans un tableau simple',
      id: 'skillExcelSimpleTable',
      subText: 'Savoir saisir des chiffres et retrouver des informations',
    },
    { text: 'Étendre une formule avec la poignée de recopie', id: 'skillExcelDragFormula' },
    {
      text: "Utiliser le '$' dans une formule pour bloquer une cellule",
      id: 'skillExcelFrozeFormula',
    },
    {
      text: 'Gérer les Tableaux Croisés Dynamiques',
      id: 'skillExcelTCD',
    },
  ],
  Outlook: [
    { text: 'Modifier la police et la couleur d’un email', id: 'skillOutlookChangeEmailFont' },
    { text: 'Envoyer un mail avec des destinataires en CC: et en CCI:', id: 'skillOutlookEmailCC' },
    {
      text: 'Créer un évènement sur mon calendrier Outlook et inviter un(e) participant(e)',
      id: 'skillOutlookCalendar',
    },
    { text: 'Créer un contact sur Outlook', id: 'skillOutlookAddContact' },
  ],
  'Excel & Word': [
    { text: "Modifier la police et la couleur d'un texte", id: 'skillWordChangeFont' },
    {
      text: 'Créer une formule de calcul sur Excel',
      id: 'skillExcelFormula',
      subText: 'Par exemple “=D3+B6” ou “=SOMME(B2:B7)”',
    },
    { text: 'Faire une table des matières sur Word', id: 'skillWordTableOfContent' },
  ],
  'La bureautique': [
    { text: "Modifier la police et la couleur d'un texte", id: 'skillWordChangeFont' },
    {
      text: 'Créer une formule sur Excel',
      id: 'skillExcelFormula',
      subText: 'Par exemple “=D3+B6” ou “=SOMME(B2:B7)”',
    },
    { text: 'Faire une table des matières sur Word', id: 'skillWordTableOfContent' },
    {
      text: 'Ajouter une animation à une diapositive dans une présentation PowerPoint',
      id: 'skillPowerpointSlidePowerpoint',
    },
  ],
};

const SkillsKnowledge: React.FC<Partial<StepWizardChildProps>> = props => {
  const theme = useTheme();
  const labelColumnSize = 1;
  const buttonsColumnSize = 1;

  const form = useForm<FormAnswer>('useFormState');
  let formState = form.getState();

  const { selfAssessedLevel, topic } = formState.values;
  if (props.goToNamedStep && !selfAssessedLevel) {
    props.goToNamedStep('topicChoice');
    return <Loader />;
  }

  const questions = QuestionsPerTopics[topic as Topic];

  const goToNextStep = () => {
    formState = form.getState();
    if (props.goToNamedStep && !formState.hasValidationErrors) {
      props.goToNamedStep('objective');
    }
  };

  const questionsJSX = questions.map(question => (
    <React.Fragment key={question.id}>
      <Grid item xs={labelColumnSize} sm={labelColumnSize}>
        <Typography
          color={theme.palette.mode === 'dark' ? 'white' : 'primary.darker'}
          fontSize="18px"
        >
          {question.text}
        </Typography>
        <Typography color={theme.palette.mode === 'dark' ? 'white' : 'grey.700'} fontSize="14px">
          {question.subText}
        </Typography>
      </Grid>
      <Grid item xs={buttonsColumnSize} sm={buttonsColumnSize}>
        <SkillKnowledgeInput name={question.id} goToNextStep={goToNextStep} />
      </Grid>
    </React.Fragment>
  ));

  return (
    <Container
      maxWidth="lg"
      sx={{
        height: '65vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingTop: 2,
      }}
    >
      <Slide timeout={1200} direction="left" in mountOnEnter unmountOnExit>
        <Container
          sx={{
            height: '40vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
          maxWidth="md"
        >
          <FormMessage>
            <Typography marginBottom="20px" color="grey.100">
              Aidez-moi à créer un programme personnalisé selon votre niveau et vos objectifs.
            </Typography>
            <Typography color="grey.50" variant="h1" fontSize="24px">
              Maitrisez-vous les fonctionnalités suivantes ?
            </Typography>
          </FormMessage>
          <Grid
            container
            columns={{ xs: 1, sm: 2 }}
            sx={{ alignItems: 'center', paddingBottom: '100px' }}
          >
            {questionsJSX}
          </Grid>
        </Container>
      </Slide>
      <NextPrevButtonGroup
        nextStep={goToNextStep}
        disableNextButton={formState.hasValidationErrors}
      />
    </Container>
  );
};

export default SkillsKnowledge;
