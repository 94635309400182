import { Box, Button, Grid, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import { Formula, FormulaCustomId } from '../entities';
import theme from '../theme';
import { FormulaTabs } from './inputs';
import TabWithChip from './TabWithChip';

type ColumnLabelProps = {
  title: string;
  price: number;
  columnSize: number;
  goToNextStep: () => void;
};

const ColumnLabel = (props: ColumnLabelProps) => (
  <Grid item sm={props.columnSize} container direction="column">
    <Typography fontSize={14} textAlign="center">
      Formule
    </Typography>
    <Typography variant="h2Program" height="48px">
      <b>{props.title}</b>
    </Typography>
    <Typography variant="body1" textAlign="center">
      {props.price.toLocaleString('fr-fr')} €
    </Typography>
    <Button
      variant="contained"
      color="success"
      disableElevation
      onClick={props.goToNextStep}
      sx={{
        fontWeight: '600',
        color: 'grey.50',
        margin: 'auto',
        maxWidth: '120px',
        textTransform: 'none',
      }}
    >
      Plus d&apos;info
    </Button>
  </Grid>
);

type FormulaTableHeaderProps = {
  formulas: Formula[];
  goToNextStep: (formula: Formula) => void;
  setSelectedFormula: (formula: Formula) => void;
};

const FormulaTableHeader = (props: FormulaTableHeaderProps) => {
  const [tabIndex, setTabIndex] = React.useState(0);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const columnSize = 2;

  const onFormulaToDisplayChange = (formulaCustomid: FormulaCustomId) => {
    const selectedFormula = props.formulas?.find(f => f.customId === formulaCustomid);
    if (selectedFormula) {
      props.setSelectedFormula(selectedFormula);
    }
  };

  const onTabChange = (event: React.SyntheticEvent, index: number) => {
    setTabIndex(index);
    switch (index) {
      case 0:
        onFormulaToDisplayChange(FormulaCustomId.d);
        break;
      case 1:
        onFormulaToDisplayChange(FormulaCustomId.c);
        break;
      case 2:
        onFormulaToDisplayChange(FormulaCustomId.b);
        break;
      default:
        break;
    }
  };

  const desktopHeader = (
    <Grid
      container
      direction="row-reverse" // Order with the most expensive formula first
      columns={16}
      sx={{
        position: 'sticky',
        top: '70px',
        paddingY: 2,
        boxShadow: '0 4px 2px -2px rgba(0,0,0,0.23)',
        backgroundColor: 'primary.lighter',
      }}
    >
      {props.formulas.map(f => (
        <ColumnLabel
          key={f.customId}
          title={f.title}
          price={f.price}
          columnSize={columnSize}
          goToNextStep={() => props.goToNextStep(f)}
        />
      ))}
    </Grid>
  );

  const mobileHeader = (
    <Box
      sx={{
        position: 'sticky',
        top: '56px',
      }}
    >
      <FormulaTabs selectedTabIndex={tabIndex} setSelectedTabIndex={onTabChange}>
        {/* Order with the most expensive formula first. Copying the array as reverse mutates the original array */}
        {[...props.formulas].reverse().map(f => (
          <TabWithChip key={f.customId} price={f.price} hours={f.hours} title={f.title} />
        ))}
      </FormulaTabs>
    </Box>
  );
  return isSmallScreen ? mobileHeader : desktopHeader;
};

export default FormulaTableHeader;
