/* eslint-disable no-underscore-dangle */
import {
  Formula,
  Level,
  Skill,
  Template,
  Topic,
  FormulaCustomId,
  getFormulaValues,
  FAQItem,
  Tag,
  Training,
  Learner,
} from '../entities';

export type CreateTrainingAndLearnerData = {
  email: string;
  objective: string;
  startingLevel: string;
  topic: Topic;
  skillsOfFormulaA: string[] | undefined;
  skillsOfFormulaB: string[] | undefined;
  skillsOfFormulaC: string[] | undefined;
  skillsOfFormulaD: string[] | undefined;
  formulaMfcLinkA: string | undefined;
  formulaMfcLinkB: string | undefined;
  formulaMfcLinkC: string | undefined;
  formulaMfcLinkD: string | undefined;
};

const axios = require('axios').default;

type SkillOfFormulaFromBubble = {
  in_amount_of_formula: number;
  formula: string;
  skill: string;
  'Created By': string;
  'Created Date': string;
  'Modified Date': string;
  index: number;
  _id: string;
};

type FormulaFromBubble = {
  custom_id: string;
  description: string;
  hours: number;
  is_selected: string;
  is_accepted: string;
  learner: string;
  mfcLink: string;
  price: number;
  title: string;
  'Created By': string;
  'Created Date': string;
  'Modified Date': string;
  _id: string;
};

type SkillFromBubble = {
  content: string;
  title: string;
  index: string;
  tag: string;
  'Created Date': string;
  'Created By': string;
  'Modified Date': string;
  _id: string;
};

type TemplateFromBubble = {
  level: Level;
  skills_of_formula_a: string[];
  skills_of_formula_b: string[];
  skills_of_formula_c: string[];
  skills_of_formula_d: string[];
  title: string;
  Created_Date: string;
  Created_By: string;
  Modified_Date: string;
  _id: string;
};

const instance = axios.create({
  baseURL: process.env.REACT_APP_BUBBLE_PRODUCTION_URL,
});

export async function getTraining(trainingId: string): Promise<Training> {
  const trainingRes = await instance.get(`/obj/training/${trainingId}`);
  const skills = await getSkills();
  if (trainingRes.status !== 200) {
    throw new Error(trainingRes.statusText);
  }

  const { topic, objectives } = trainingRes.data.response;
  const learnerInfo = await getLearnerInfo(trainingRes.data.response.learner);

  const formulas = await getFormulasForLearner(trainingId);

  const [formulaB, formulaC, formulaD] = await Promise.all([
    getFormulaWithSkills(formulas, FormulaCustomId.b, skills, topic),
    getFormulaWithSkills(formulas, FormulaCustomId.c, skills, topic),
    getFormulaWithSkills(formulas, FormulaCustomId.d, skills, topic),
  ]);

  return {
    learner: learnerInfo,
    formulas: [formulaB, formulaC, formulaD],
    topic,
    objective: objectives,
  };
}

async function getLearnerInfo(learnerId: string): Promise<Learner> {
  const res = await instance.get(`/obj/learner/${learnerId}`);
  if (res.status !== 200) {
    throw new Error(res.statusText);
  }

  const userRes = await instance.get(`/obj/user/${res.data.response.user}`);

  const learnerInfo: Learner = {
    email: userRes.data.response.authentication.email.email,
    firstName: res.data.response.first_name,
    lastName: res.data.response.last_name ? res.data.response.last_name : '',
    phoneNumber: res.data.response.phone,
  };

  return learnerInfo;
}

async function getFormulasForLearner(trainingId: string): Promise<FormulaFromBubble[]> {
  const formulasRes = await instance.get(
    `/obj/formula?constraints=[{"key": "training", "constraint_type": "equals", "value": "${trainingId}"}]`,
  );

  if (formulasRes.status !== 200) {
    throw new Error(formulasRes.statusText);
  }

  if (formulasRes.data.response.count !== 4) {
    throw new Error(`abnormal amount of formulas`);
  }

  return formulasRes.data.response.results;
}

async function getFormulaWithSkills(
  formulas: FormulaFromBubble[],
  customId: FormulaCustomId,
  skills: Skill[],
  topic: Topic,
): Promise<Formula> {
  const formula = formulas.find(f => f.custom_id === customId);

  if (!formula) {
    throw new Error(`Could not find ${customId}`);
  }

  const skillOfFormula = await getSkillsOfFormula(formula._id);

  const formulaWithSkills = {
    ...getFormulaValues(customId, topic),
    skills: skillOfFormula.map((sof: SkillOfFormulaFromBubble) => findSkill(sof.skill, skills)),
    id: formula._id,
  };

  return formulaWithSkills;
}

function findSkill(id: string, skills: Skill[]): Skill {
  const skill = skills.find((s: Skill) => s.id === id);

  if (!skill) {
    throw new Error(`Could not find skill ${id}`);
  }

  return skill;
}

export async function createTrainingAndLearner(trainingData: CreateTrainingAndLearnerData) {
  const res = await instance.post('/wf/create-training', trainingData);

  if (res.status !== 200) {
    throw new Error(res.statusText);
  }

  return {
    trainingId: res.data.response.trainingId,
    learnerId: res.data.response.learnerId,
  };
}

export async function updateLearnerContactInfo(contactInfo: {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
}) {
  const res = await instance.post('/wf/update-contact-info-for-new-prospect', contactInfo);

  if (res.status !== 200) {
    throw new Error(res.statusText);
  }

  return res.data.response.trainingId;
}

async function getSkillsOfFormula(formulaId: string): Promise<SkillOfFormulaFromBubble[]> {
  const res = await instance.get(
    `/obj/skillofformula?constraints=[{"key": "formula", "constraint_type": "equals", "value": "${formulaId}"}]`,
  );

  if (res.status !== 200) {
    throw new Error(res.statusText);
  }
  return res.data.response.results;
}

export async function getSkills(): Promise<Skill[]> {
  const [tagRes, skillRes] = await Promise.all([
    instance.get('/obj/tag'),
    instance.get('/obj/skill'),
  ]);

  if (tagRes.status !== 200) {
    throw new Error(tagRes.statusText);
  }

  if (skillRes.status !== 200) {
    throw new Error(skillRes.statusText);
  }

  const skills = skillRes.data.response.results.map((skill: SkillFromBubble) => ({
    content: skill.content,
    index: skill.index,
    title: skill.title,
    id: skill._id,
    tag: tagRes.data.response.results.find((s: Tag & { _id: string }) => s._id === skill.tag),
  }));

  return skills;
}

export async function getTemplates(): Promise<Template[]> {
  const res = await instance.get('/obj/template');

  if (res.status !== 200) {
    throw new Error(res.statusText);
  }

  const templates = res.data.response.results.map((template: TemplateFromBubble) => ({
    skillsOfFormulaA: template.skills_of_formula_a,
    skillsOfFormulaB: template.skills_of_formula_b,
    skillsOfFormulaC: template.skills_of_formula_c,
    skillsOfFormulaD: template.skills_of_formula_d,
    title: template.title,
    level: template.level,
    id: template._id,
  }));

  return templates;
}

export async function getFAQItems(): Promise<FAQItem[]> {
  const res = await instance.get('/obj/faqitem');

  if (res.status !== 200) {
    throw new Error(res.statusText);
  }
  const FAQItems = res.data.response.results.map((item: FAQItem) => ({
    content: item.content,
    title: item.title,
  }));
  return FAQItems;
}

export async function setChoosenFormula(selectedFormulaId: string) {
  if (process.env.NODE_ENV === 'development') {
    return null;
  }
  const res = await instance.post('/wf/set-choosen-formula', {
    formulaId: selectedFormulaId,
  });
  if (res.status !== 200) {
    throw new Error(res.statusText);
  }

  return res.status;
}

export async function sendMCFLinkClickedEmail(trainingId: string) {
  if (process.env.NODE_ENV === 'development') {
    return null;
  }
  const res = await instance.post('/wf/send-mcf-link-clicked-email', {
    trainingId,
  });
  if (res.status !== 200) {
    throw new Error(res.statusText);
  }

  return res.status;
}
