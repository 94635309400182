import { Box, Container, Grid, Slide, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import { StepWizardChildProps } from 'react-step-wizard';
import { FAQItem, ProgramFormulas } from '..';
import { FAQItem as FAQItemType, Formula, Objective, Topic } from '../../entities';
import heroImageExcel from '../../images/atoria_hero_excel.jpg';
import heroImageOutlook from '../../images/atoria_hero_outlook.jpg';
import heroImagePowerpoint from '../../images/atoria_hero_powerpoint.jpg';
import heroImageWord from '../../images/atoria_hero_word.jpg';
import emojiTeacher from '../../images/teacher.png';
import emojiRuler from '../../images/straight-ruler.png';
import emojiCalendar from '../../images/spiral-calendar.png';
import logoCpf from '../../images/logo_mcf_simple.png';
import theme from '../../theme';
import { setChoosenFormula } from '../../http/bubble';

const heroImageHashTable = {
  Excel: heroImageExcel,
  Outlook: heroImageOutlook,
  'Excel & Word': heroImageWord,
  'La bureautique': heroImagePowerpoint,
};

const topicAdaptedForText = {
  Excel: 'Excel',
  Outlook: 'Outlook',
  'Excel & Word': 'Excel & Word',
  'La bureautique': 'Bureautique',
};

type ProgramSinglePageProps = Partial<StepWizardChildProps> & {
  formulas: Formula[];
  faqItems: FAQItemType[];
  topic: Topic;
  objective: Objective;
  selectedFormula: Formula;
  setSelectedFormula: (formula: Formula) => void;
  firstName?: string;
};

const ProgramSinglePage: React.FC<ProgramSinglePageProps> = props => {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const titleHashtable = {
    improveProductivity: (
      <>
        <Typography variant="h1Program" component="h1" sx={{ marginBottom: 0 }}>
          <b>
            {props.firstName ? `${props.firstName}, v` : 'V'}ous voulez améliorer votre productivité
            ?
          </b>
        </Typography>
        <Typography variant="h1Program" component="h1" sx={{ marginTop: 1 }}>
          <b>
            Notre formation {topicAdaptedForText[props.topic]} vous fera gagner du temps et de
            l&apos;efficacité !
          </b>
        </Typography>
      </>
    ),
    searchJob: (
      <>
        <Typography variant="h1Program" component="h1" sx={{ marginBottom: 0 }}>
          <b>{props.firstName ? `${props.firstName}, v` : 'V'}ous cherchez un emploi ?</b>
        </Typography>
        <Typography variant="h1Program" component="h1" sx={{ marginTop: 1 }}>
          <b>
            Suivez notre formation {topicAdaptedForText[props.topic]} pour ajouter une compétence à
            votre CV !
          </b>
        </Typography>
      </>
    ),
    changeJob: (
      <>
        <Typography variant="h1Program" component="h1" sx={{ marginBottom: 0 }}>
          <b>{props.firstName ? `${props.firstName}, v` : 'V'}ous changez de métier ?</b>
        </Typography>
        <Typography variant="h1Program" component="h1" sx={{ marginTop: 1 }}>
          <b>
            Notre formation {topicAdaptedForText[props.topic]} vous fera réussir votre prise de
            poste !
          </b>
        </Typography>
      </>
    ),
    other: (
      <>
        <Typography variant="h1Program" component="h1" sx={{ marginBottom: 0 }}>
          <b>
            {props.firstName ? `${props.firstName}, b` : 'B'}esoin de progresser sur{' '}
            {topicAdaptedForText[props.topic]} ?
          </b>
        </Typography>
        <Typography variant="h1Program" component="h1" sx={{ marginTop: 1 }}>
          <b>Notre formation vous permettra d&apos;apprendre efficacement !</b>
        </Typography>
      </>
    ),
  };
  const goToNextStep = (formula: Formula) => {
    if (formula && formula.id && props.nextStep) {
      props.setSelectedFormula(formula);
      setChoosenFormula(formula.id);
      props.nextStep();
    }
  };

  return (
    <Container
      maxWidth="xl"
      sx={{
        marginBottom: '100px',
        height: '100%',
        padding: isSmallScreen ? '10px' : '16px',
      }}
    >
      <Slide timeout={1000} direction="left" in mountOnEnter unmountOnExit>
        <Grid container direction="column">
          <Grid item container direction="row">
            <Grid item sm={5}>
              <img
                src={heroImageHashTable[props.topic]}
                alt="Utilisateur en conference avec un formateur"
                style={{ width: isSmallScreen ? '100%' : '90%', marginTop: '36px' }}
              />
              <Grid container columns={2} direction="row" spacing={2}>
                <Grid item xs={1}>
                  <Box className="elfsight-app-c7363db2-d9ac-4262-a4a0-9269ff9f7674" />
                </Grid>
                <Grid item xs={1}>
                  <img
                    src={logoCpf}
                    alt="Logo Officiel CPF"
                    style={{
                      display: 'block',
                      width: isSmallScreen ? '60%' : '50%',
                      maxWidth: '72px',
                      marginLeft: '32px',
                      marginTop: '12px',
                    }}
                  />
                  Eligible 100% CPF
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={7} flexGrow={2}>
              {titleHashtable[props.objective]}
              <Grid item container direction="row" columns={{ xs: 10 }}>
                <Grid item xs={2}>
                  <img
                    src={emojiTeacher}
                    alt="emoji professeur"
                    style={{
                      display: 'block',
                      maxWidth: '40px',
                      marginLeft: 'auto',
                      paddingRight: '10px',
                      paddingTop: '16px',
                    }}
                  />
                </Grid>
                <Grid item xs={8}>
                  <Typography
                    marginLeft={isSmallScreen ? '12px' : '0'}
                    marginTop="12px"
                    variant="body2"
                    sx={{ color: 'grey.600' }}
                  >
                    <b>Un super formateur en cours particuliers</b>
                  </Typography>
                  <Typography
                    marginLeft={isSmallScreen ? '12px' : '0'}
                    marginTop="12px"
                    variant="body2"
                    sx={{ marginTop: 0 }}
                  >
                    Soigneusements sélectionnés pour sa pédagogie et son expertise.
                  </Typography>
                </Grid>
              </Grid>

              <Grid item container direction="row" columns={{ xs: 10 }}>
                <Grid item xs={2}>
                  <img
                    src={emojiCalendar}
                    alt="emoji calendrier"
                    style={{
                      display: 'block',
                      maxWidth: '40px',
                      marginLeft: 'auto',
                      paddingRight: '10px',
                      paddingTop: '16px',
                    }}
                  />
                </Grid>
                <Grid item xs={8}>
                  <Typography
                    marginLeft={isSmallScreen ? '12px' : '0'}
                    marginTop="12px"
                    variant="body2"
                    sx={{ color: 'grey.600' }}
                  >
                    <b>En visioconférence par sessions de 2h</b>
                  </Typography>
                  <Typography
                    marginLeft={isSmallScreen ? '12px' : '0'}
                    marginTop="12px"
                    variant="body2"
                    sx={{ marginTop: 0 }}
                  >
                    Quand vous le voulez de 7h à 23h, du lundi au dimanche !
                  </Typography>
                </Grid>
              </Grid>

              <Grid item container direction="row" columns={{ xs: 10 }}>
                <Grid item xs={2}>
                  <img
                    src={emojiRuler}
                    alt="emoji professeur"
                    style={{
                      display: 'block',
                      maxWidth: '40px',
                      marginLeft: 'auto',
                      paddingRight: '10px',
                      paddingTop: '16px',
                    }}
                  />
                </Grid>
                <Grid item xs={8}>
                  <Typography
                    marginLeft={isSmallScreen ? '12px' : '0'}
                    marginTop="12px"
                    variant="body2"
                    sx={{ color: 'grey.600' }}
                  >
                    <b>Pas de vidéo pré-enregistrée ou de classe collective</b>
                  </Typography>
                  <Typography
                    marginLeft={isSmallScreen ? '12px' : '0'}
                    marginTop="12px"
                    variant="body2"
                    sx={{ marginTop: 0 }}
                  >
                    Programme 100% individualisé. Travaillez à 2 sur vos fichiers.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* Formulas */}
          <Typography variant="h1Program">Le Programme de notre formation</Typography>
          <ProgramFormulas
            selectedFormula={props.selectedFormula}
            setSelectedFormula={props.setSelectedFormula}
            formulas={props.formulas}
            goToNextStep={goToNextStep}
          />
          {/* Google comment */}
          <Typography variant="h1Program">Ils ont suivi leur formation avec nous</Typography>
          <Box sx={{ margin: 2 }} className="elfsight-app-653afff0-bff6-4d21-869c-a2c639d1af4d" />
          {/* FAQ */}
          <Typography variant="h1Program">Questions Fréquentes</Typography>
          <Container maxWidth="lg">
            {props.faqItems.map(item => (
              <FAQItem key={item.title} item={item} />
            ))}
          </Container>
        </Grid>
      </Slide>
    </Container>
  );
};

export default ProgramSinglePage;
