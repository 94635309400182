import React, { useState } from 'react';
import { Container, Link, Typography } from '@mui/material';
import { CalendlyEventListener, InlineWidget } from 'react-calendly';
import { StepWizardChildProps } from 'react-step-wizard';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { ProgramFooter } from '..';
import { sendMCFLinkClickedEmail } from '../../http/bubble';
import { Formula } from '../../entities';

type ProgramDatePageProps = Partial<StepWizardChildProps> & {
  selectedFormula: Formula;
  email: string;
  trainingId: string;
  phoneNumber: string;
  fullName: string;
};

const ProgramDatePage: React.FC<ProgramDatePageProps> = props => {
  window.scrollTo(0, 0);
  const [isBookingDone, setIsBookingDone] = useState<Boolean>(false);
  const handleEvent = () => {
    if (props.nextStep) {
      setIsBookingDone(true);
    }
  };

  const calendlyUrl = `https://calendly.com/camille-atoria/formation?hide_gdpr_banner=1`;

  return (
    <Container
      sx={{
        marginBottom: '150px',
        height: '100%',
      }}
    >
      <Typography variant="h1Program" sx={{ marginBottom: '0px' }}>
        Prendre un rendez-vous avec Camille pour discuter de votre formation
      </Typography>
      <CalendlyEventListener onEventScheduled={handleEvent} />
      <InlineWidget
        url={calendlyUrl}
        styles={{
          height: '750px',
        }}
        prefill={{
          email: props.email,
          name: props.fullName,
          location: props.phoneNumber,
        }}
      />
      <Typography variant="body2" textAlign="center">
        <Link
          onClick={() => sendMCFLinkClickedEmail(props.trainingId)}
          data-heap-link={props.selectedFormula?.mfcLink}
          href={props.selectedFormula?.mfcLink}
          underline="none"
          target="_blank"
        >
          <ArrowForwardIcon
            sx={{
              marginRight: '4px',
              marginBottom: '4px',
              verticalAlign: 'middle',
            }}
          />
          m&apos;inscrire directement avec mon CPF
        </Link>
      </Typography>
      <ProgramFooter
        previousStep={props.previousStep}
        nextStep={props.nextStep}
        disableNextButton={!isBookingDone}
        selectedFormula={props.selectedFormula}
      />
    </Container>
  );
};

export default ProgramDatePage;
