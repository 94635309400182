import { Box, Typography } from '@mui/material';
import React from 'react';
import { FAQItem as FAQItemType } from '../entities';
import theme from '../theme';

type FAQItemProps = {
  item: FAQItemType;
};

const FAQItem = ({ item }: FAQItemProps) => (
  <Box
    key={item.title}
    sx={{
      backgroundColor: theme.palette.primary.lighter,
      padding: 4,
      marginY: 2,
    }}
  >
    <Typography variant="h3">{item.title}</Typography>
    <Typography variant="body2">{item.content}</Typography>
  </Box>
);

export default FAQItem;
